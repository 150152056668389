<!-- eslint-disable -->
<template>
    <div>
        <DeletionDialog
            type="Connector"
            :name="connector.name"
            :show="showDelete"
            @cancel="showDelete = false"
            @delete="deleteConnector(connector)"
        >
        </DeletionDialog>
    
        <Dialog     
            :title="`${connector.id === undefined ? 'Add' : 'Update'} Connector`"
            :show="show"
            @close="show = false"
        >
            <ValidationObserver ref="connector" v-slot="{ invalid }">
                <v-card-title class="subtitle-2">General</v-card-title>
                <v-card-text>                    
                    <ValidationProvider name="tenant" rules="required"  v-slot="{ errors, valid }">  
                        <v-autocomplete
                            label="Tenant"
                            outlined
                            v-model="connector.tenantId"
                            :items="tenants"
                            item-text="name"
                            item-value="id"
                            no-data-text="No Tenants!"
                            clearable
                            :error-messages="errors"
                            :success="valid"
                            > 
                        </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider name="client" rules="required"  v-slot="{ errors, valid }">  
                        <v-autocomplete
                            label="Client"
                            :disabled="connector.id !== undefined"
                            outlined
                            v-if="clients"
                            v-model="connector.client"
                            :items="Object.keys(clients)"
                            no-data-text="No clients!"
                            clearable
                            :error-messages="errors"
                            :success="valid"
                            > 
                        </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider name="version" rules="required"  v-slot="{ errors, valid }">  
                        <v-autocomplete
                            label="Version"
                            outlined
                            @change="init()"
                            v-if="clients[connector.client]"
                            v-model="connector.clientVersion"
                            :items="Object.keys(clients[connector.client])"
                            item-text="name"
                            item-value="id"
                            no-data-text="No versions!"
                            clearable
                            :error-messages="errors"
                            :success="valid"
                            > 
                        </v-autocomplete>
                    </ValidationProvider>
                    <ValidationProvider name="name" rules="required"  v-slot="{ errors, valid }">   
                        <v-text-field
                            label="Name"
                            outlined
                            v-model="connector.name"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="description" v-slot="{ errors, valid }">   
                        <v-textarea
                            label="Description"
                            outlined
                            v-model="connector.description"
                            :error-messages="errors"
                            :success="valid"
                        ></v-textarea>
                    </ValidationProvider>
                </v-card-text>

                <!-- Parameters -->
                <div v-if="clients[connector.client] 
                    && clients[connector.client][connector.clientVersion] 
                    && clients[connector.client][connector.clientVersion].parameters"
                >
                    <v-card-title class="subtitle-2">Connector Specific</v-card-title>
                    <v-card-text>
                        <div v-for="parameter in clients[connector.client][connector.clientVersion].parameters" :key="parameter.name">
                            <div v-if="parameter.type === 'arrayAutocomplete'">
                                <ValidationProvider :name="parameter.name" :rules="parameter.required ? 'required' : ''" v-slot="{ errors, valid }">  
                                    <v-autocomplete
                                        :label="parameter.name"
                                        :hint="parameter.hint"
                                        clearable
                                        chips
                                        outlined
                                        :items="Object.values(parameter.inputValues)"
                                        item-text="name"
                                        item-value="id"
                                        :multiple="parameter.multiple ? parameter.multiple : true"
                                        deletable-chips
                                        v-model="connector.parameters[parameter.name]"
                                        :error-messages="errors"
                                        :success="valid"
                                    ></v-autocomplete>
                                </ValidationProvider>
                            </div>
                            <div v-else-if="parameter.type === 'arrayCombobox'">
                                <ValidationProvider :name="parameter.name" :rules="parameter.required ? 'required' : ''" v-slot="{ errors, valid }">  
                                    <v-combobox
                                        :label="parameter.name"
                                        :hint="parameter.hint"
                                        clearable
                                        chips
                                        outlined
                                        :items="Object.values(parameter.inputValues)"
                                        item-text="name"
                                        item-value="id"
                                        :multiple="parameter.multiple ? parameter.multiple : true"
                                        deletable-chips
                                        v-model="connector.parameters[parameter.name]"
                                        :error-messages="errors"
                                        :success="valid"
                                    ></v-combobox>
                                </ValidationProvider>
                            </div>
                            <div v-else>
                                <ValidationProvider :name="parameter.name" :rules="parameter.required ? 'required' : ''" v-slot="{ errors, valid }">  
                                    <v-text-field
                                        :label="parameter.name"
                                        :hint="parameter.hint"
                                        outlined
                                        :type="parameter.type === 'string' ? 'text' : parameter.type"
                                        v-model="connector.parameters[parameter.name]"
                                        :error-messages="errors"
                                        :success="valid"
                                    ></v-text-field>
                                </ValidationProvider>
                            </div>
                        </div>
                     </v-card-text>
                </div>
                <v-card-text v-if="connector.id === undefined">
                    <strong>*Nextsteps:</strong>
                        <br>1. Await quotation;
                        <br>2. Accept quotation and trial period;
                        <br>3. Await connector activation;
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="connector.id !== undefined" color="error" outlined @click="showDeleteConnector(connector)">Delete</v-btn>
                    <v-btn v-if="connector.id !== undefined" color="primary" @click="patchConnector(connector)" :loading="loading" :disabled="invalid">Save</v-btn>
                    <v-btn v-else color="primary" @click="postConnector(connector)" :loading="loading" :disabled="invalid">Add</v-btn>
                </v-card-actions> 
            </ValidationObserver>  
        </Dialog>
    </div>
</template>

<script>
import DeletionDialog from '../DeletionDialog.vue'
import Dialog from '../Dialog.vue'

export default {
    components: { 
        Dialog,
        DeletionDialog
    },

    props: [
        "connector",
        "tenants",
        "clients"
    ],

    created() {
        this.init()
    },

    methods: {
        init() {
            if(this.clients[this.connector.client] 
                && this.clients[this.connector.client][this.connector.clientVersion] 
                && this.clients[this.connector.client][this.connector.clientVersion].parameters
            ) {
                const parameters = {}
                if(this.connector.__client && this.connector.__client.parameters) {
                    for(const parameter of this.connector.__client.parameters) {
                        if(this.connector.parameters[parameter.name]) {
                            parameters[parameter.name] = this.connector.parameters[parameter.name]
                        } 
                        else if (parameter.defaultValues) {
                            parameters[parameter.name] = parameter.defaultValues
                        } 
                        else {
                            parameters[parameter.name] = parameter.type === 'array' ? [] : null
                        }
                    }
                }
                this.connector.parameters = parameters
            }        
        },

        doShow() {
            this.show = true
        },

        async showDeleteConnector(connector) {
            this.connector = connector
            this.showDelete = true
        },

        async deleteConnector(connector) {
            this.loading = true
            const connectorId = connector.id
            await this.$http.delete(`/connectors/${connectorId}`)
            this.$emit('save')
            this.loading = false
            this.show = false
        },

        async patchConnector(connector) {
            this.loading = true
            const connectorId = connector.id
            const patch = {
                tenantId: connector.tenantId,
                client: connector.client,
                clientVersion: connector.clientVersion,
                name: connector.name,
                description: connector.description,
                parameters: connector.parameters,
            }
            await this.$http.patch(`/connectors/${connectorId}`, patch)
            this.$emit('save')
            this.loading = false
            this.show = false
        },

        async postConnector(connector) {
            this.loading = true
            try{
                await this.$http.post(`/tenants/${connector.tenantId}/connectors/`, connector)
                this.$emit('save')
                window.snackbar.queue.push({
                    color: 'success',
                    text: "Succesfully, created the connector!",
                })
            // eslint-disable-next-line
            } catch(err){}

            this.loading = false
            this.show = false
        }

    },

    data: () => {
        return {
            loading: false,
            show: false,
            showDelete: false
        }
    }
}
</script>

<style>

</style>