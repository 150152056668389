<!-- eslint-disable -->
<template>
    <Dialog     
        title="Info Tenant"
        :show="show"
        @close="show = false"
    >
        <v-text-field
            label="ID"
            readonly
            outlined
            :value="tenant.id"
            append-icon="mdi-content-copy"
            @click:append="copyText(tenant.id);"
        ></v-text-field>
    </Dialog>
</template>

<script>
import Dialog from '../Dialog.vue'

export default {
    components: { 
      Dialog
    },

    props: [
        "tenant"
    ],

    computed: {

    },

    methods: {
        doShow() {
            this.show = true
        },
        copyText(text) {
            console.info('Copied to clipboard');
            navigator.clipboard.writeText(text);
        }
    },

    data: () => {
        return {
            show: false
        }
    }
}
</script>

<style>

</style>