<!-- eslint-disable -->
<template>
    <Dialog     
        title="API Access token"
        :show="show"
        width="650px"
        @close="$emit('cancel')"
    >
        <v-card-text>
             <v-text-field
                    v-if="hasApiToken"
                    label="API Access Token"
                    :type="copyableToken ? 'text' : 'password'"
                    readonly
                    outlined
                    :value="apiToken"
                    :append-icon="copyableToken ? 'mdi-content-copy' : null"
                    @click:append="copyText(apiToken);"
            ></v-text-field>

        <p class="red--text">The created token will only be visable once, please copy it and store in a safe location</p>
  
        <p class="red--text" v-if="hasApiToken">Preforming a token reset would result in deletion of old active tokens for the current user!</p>
    
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!hasApiToken" color="primary" :loading="loading" @click="generateAccessToken(user)">Create Token</v-btn>
        <v-btn v-else color="error" :loading="loading" @click="generateAccessToken(user)">Reset Token</v-btn>
        </v-card-actions>
    </Dialog>
</template>

<script>
import Dialog from '../Dialog.vue'

export default {
    components: { 
      Dialog
    },

    props: [
        "user",
        'show'
    ],
    async created() {
        if(this.user && this.user.id) {
            await this.getHasAccesstToken(this.user)
        }
  
    },


    methods: {
        copyText(text) {
            console.info('Copied to clipboard');
            navigator.clipboard.writeText(text);
            window.snackbar.queue.push({
                color: "success",
                text: `Copied token to clipboard!`,
            });
        },

        async getHasAccesstToken(user) {
            this.loading = true
            const {data} = await this.$http.get(`/user/${user.id}/hasApiAccessToken`)
            this.hasApiToken = data
            this.loading = false
        },

        async generateAccessToken(user) {
            this.loading = true
            const {data} = await this.$http.post(`/user/${user.id}/generateApiToken`, {})
            this.apiToken = data.apiToken
            this.copyableToken = true
            this.hasApiToken = true
            this.loading = false
        },
    },

    data: () => {
        return {
            hasApiToken : false,
            copyableToken : false,
            apiToken: "**************************************************************************",
            loading : false
        }
    }
}
</script>

<style>

</style>