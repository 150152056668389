<template>
    <v-app>
        <!-- login -->
        <template v-if="!accessToken">        
            <login/>        
        </template>
        <!-- App -->
        <template v-else>      
            <app-private></app-private>
        </template>
        <app-dev-bar></app-dev-bar>
        <snack-bar> </snack-bar>
    </v-app>
</template>

<script>
  import { mapState } from 'vuex'
  import SnackBar from '@/components/SnackBar.vue'
  import Login from '@/components/Login.vue'
  import AppPrivate from '@/AppPrivate.vue'
  import AppDevBar from '@/AppDevBar.vue'



  export default {
    components: {
      SnackBar,
      Login,
      AppPrivate,
      AppDevBar
    },

    watch: {
      accessToken: function (newVal) {
        const recaptcha = this.$recaptchaInstance
        if(!newVal) {
          recaptcha.showBadge()
        } else {
          recaptcha.hideBadge()
        }       
      }
    },
    
    async created() {
      // Set AccessToken again after reload
      this.$http.defaults.headers.common['Authorization'] = `Bearer ${this.accessToken}`;
    },

    computed: {
      ...mapState(['accessToken']),
    }
  }
</script>

<style>
</style>
