<template>
  <v-navigation-drawer
    app
    permanent
    expand-on-hover
    mini-variant
  >
    <v-list>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-menu</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
    <v-list-item 
   v-for="item in menu"
    :key="item.name" 
    :to="item.link ? item.link : undefined" 
    :href="item.url ? item.url : undefined"
    :target="item.url ? '_blank' : undefined"
    :rel="item.url ? 'noopener noreferrer' : undefined">
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ item.name }}</v-list-item-title>
    </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>


<script>

export default {

  props: [
    ],

  data() {
    return {
      menu: [
        {
          name: "Connectors",
          icon: "mdi-link",
          link: "/connectors"
        },
        {
          name: "Tenants",
          icon: "mdi-account-group",
          link: "/tenants"
        },
        {
          name: "Logs",
          icon: "mdi-alert-outline",
          link: "/logs"
        },
        {
          name: "Requests",
          icon: "mdi-swap-vertical",
          link: "/requests"
        },
        {
          name: "Jobs",
          icon: "mdi-tray-full",
          link: "/jobs-queue"
        },
        {
          name: "Info",
          icon: "mdi-information-slab-circle-outline",
          url: "https://docs.cleversight.one/content/82550/manual_-_dwh_get_started"
        }
      ]

    }
  },

  computed: {
  },
};
</script>

<style>
body .v-application--wrap .v-navigation-drawer {
    max-height: initial !important;
}
</style>
