<template>
 <div v-if="Object.keys(usagesTotalsPerDay).length > 0">
    <v-card
        class="mx-auto"
        outlined
    >
        <v-list-item-title class="text mx-2">
           Usage per Day
        </v-list-item-title>
        <line-chart :chartData="chartData"></line-chart>
    </v-card>
 </div>
</template>

<script>
import LineChart from './base/LineChart.vue';

const getSizeScale = (sizeBytes) => {
    if (!+sizeBytes) return {
        divider: 1,
        sizeLabel: 'Bytes'
    }
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(sizeBytes) / Math.log(k))
    return {
        divider: Math.pow(k, i),
        sizeLabel: sizes[i]
    }
}
const setOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;

export default {
    components: {
        LineChart
        
    },

    props: [
        "usagesTotalsPerDay"
    ],

    computed: {
        chartData() {
            const labels = [] //[ 'January', 'February', 'March' ]
            const dataSetSizeBytes = {
                label: '',
                pointBorderColor: 'white',
                pointBackgroundColor: 'white',
                borderColor: "#FFA300",
                backgroundColor: setOpacity("#FFA300", 0.5),
                fill: true,
                tension: 0.1,
                data: [],
            }

            const dataSetResponseCount = {
                label: 'Response count',
                hidden: true,
                pointBorderColor: 'white',
                pointBackgroundColor: 'white',
                borderColor: "#FFDA99",
                backgroundColor: setOpacity("#FFDA99", 0.5),
                fill: true,
                tension: 0.1,
                data: [] 
            }

            const dataSetRequestCount = {
                label: 'Request count',
                hidden: true,
                pointBorderColor: 'white',
                pointBackgroundColor: 'white',
                borderColor: "#FFEDCC",
                backgroundColor: setOpacity("#FFEDCC", 0.5),
                fill: true,
                tension: 0.1,
                data: [] 
            }
            
            const maxSize = Math.max(...Object.values(this.usagesTotalsPerDay).map(i => i.sizeBytes))
            const sizeScale = getSizeScale(maxSize)
            dataSetSizeBytes.label = `Size (${sizeScale.sizeLabel})`

            // Sort by Date
            const usagesTotalsPerDaySorted = Object.fromEntries(
                Object.entries(this.usagesTotalsPerDay).sort(([a,],[b,]) => new Date(a) - new Date(b) )
            )

            // create labels and add
            for(const [label, values] of Object.entries(usagesTotalsPerDaySorted)) {

                labels.push(new Date(label).toLocaleString().split(',')[0])
                dataSetSizeBytes.data.push( (values.sizeBytes / sizeScale.divider).toFixed(2))
                dataSetRequestCount.data.push(values.requestCount)
                dataSetResponseCount.data.push(values.responseCount)
               
            }
            return {
                labels,
                datasets: [
                    dataSetSizeBytes,
                    dataSetResponseCount,
                    dataSetRequestCount
                ]                
            }

        }
    },


    data() {
        return {
    
        }
    }

}
</script>

<style>
</style>