<!-- eslint-disable -->
<template>
  <v-layout row justify-center>
    <v-dialog 
      v-model="show"
   
      :fullscreen="fullscreen"
      transition="dialog-bottom-transition"
      :max-width="width"
      @click:outside="$emit('close')"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="$emit('close')">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <div class="pa-5">
          <slot></slot>
        </div>
      </v-card>      
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: "950px"
    },
    show: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },  
};
</script>
