<template>
  <div>
       <!-- Snackbar Alerts -->
       <v-snackbar 
        v-for="(current, i) in queue"
        :key="i"
        :timeout="10000" :style="{'margin-bottom':calcMargin(i)}"
        :color="current.color"
        v-model="show"
        bottom
        right
      >
          {{ current.text }}
        <v-btn dark @click.native="closeSnackbar()" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar> 
  </div>
</template>

<script>
export default {
  created() {
    window.snackbar = this;
  },

  data() {
    return {
      show: false,
      current: {
        color: null,
      },
      queue: [],
    }
  },

  methods: {
    nextSnackbar() {
      if (this.queue.length > 0) {
        if (!this.show || this.queue[0].force) {
          // get first item, delete it from que and show
          this.current = this.queue[0];
          this.queue.shift();
          this.show = true;
        }
      }
    },

    calcMargin(i) {
            return (i*80) + 'px'
    },

    closeSnackbar() {
      this.show = false;
    },
  },

  watch: {
    queue() {
      this.nextSnackbar();
    },
    show() {
      this.nextSnackbar();
    },
  },
};
</script>
