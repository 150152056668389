<!-- eslint-disable -->
<template>
    <div>
        <Dialog     
            :title="`Invite user`"
            :show="show"
            width="500px"
            @close="show = false"
        >
            <ValidationObserver ref="tenantAddUserObserver" v-slot="{ invalid }">
                <v-card-text>        
                    <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">        
                          <v-text-field 
                            append-icon="mdi-email" 
                            label="Email" 
                            v-model="email" 
                            outlined
                            :error-messages="errors"
                            :success="valid"
                          ></v-text-field>
                        </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="inviteUser(email)" :loading="loading" :disabled="invalid">Invite User</v-btn>
                </v-card-actions> 
            </ValidationObserver>
        </Dialog>
    </div>
</template>

<script>
import Dialog from '../Dialog.vue'

export default {
    components: { 
      Dialog
    },

    props: [
        "tenant",
        "user"
    ],

    computed : {
    },

    methods: {
        doShow() {            
            this.show = true
        },

        async inviteUser(email) {
            this.loading = true
            try {
                await this.$http.put(`/tenants/${this.tenant.id}/users/rel/${this.user.id}/invite/${email}`) 
                this.$emit('save')
                this.loading = false
                this.show = false
            }   catch {
                this.loading = false
            }
        }
    },

    data: () => {
        return {
            loading: false,
            show: false,
            email: ""
        }
    }
}
</script>

<style>

</style>