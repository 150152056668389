<!-- eslint-disable -->
<template>
    <div>
        <DeletionDialog
            type="Speed Cache"
            :name="connector.name"
            :show="showDropSpeed"
            @cancel="showDropSpeed = false"
            @delete="dropSpeedCache(connector)"
        >
        </DeletionDialog>

        <DeletionDialog
            type="Incremental Cache Lock"
            :name="connector.name"
            :show="showDropIncremental"
            @cancel="showDropIncremental = false"
            @delete="dropIncrementalCache(connector)"
        >
        </DeletionDialog>

        <DeletionDialog
            type="Perstistent Cache"
            :name="connector.name"
            :show="showDropPersistent"
            @cancel="showDropPersistent = false"
            @delete="dropPersistentCache(connector)"
        >
        </DeletionDialog>

        <Dialog     
            :title="`Caches: ${connector.name}`"
            :show="show"
            fullscreen
            @close="show = false"
        >
            <v-card flat>
                <v-row>
                    <v-col> 
                        <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item two-line>
                                <v-list-item-avatar>
                                    <v-icon x-large>
                                        mdi-database-clock
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h4 mb-1">
                                        {{ cachesTotalSpeed}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Speed Cache</v-list-item-subtitle>
                                </v-list-item-content>              
                            </v-list-item>
            
                            <v-card-actions>
                                <v-spacer />
                                <v-btn @click="showDropSpeed = true" color="secondary" small rounded>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn> 
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col> 
                        <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item two-line>
                                <v-list-item-avatar>
                                    <v-icon x-large>
                                        mdi-database-clock
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h4 mb-1">
                                        {{ cachesTotalIncremental}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Incremental Cache</v-list-item-subtitle>
                                </v-list-item-content>              
                            </v-list-item>
            
                            <v-card-actions>
                                <v-spacer />
                                <v-btn @click="showDropIncremental = true" color="secondary" small rounded>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn> 
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col> 
                        <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item two-line>
                                <v-list-item-avatar>
                                    <v-icon x-large>
                                        mdi-database-clock
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h4 mb-1">
                                        {{ cachesTotalPersitent }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Persistent Cache</v-list-item-subtitle>
                                </v-list-item-content>              
                            </v-list-item>
            
                            <v-card-actions>
                                <v-spacer />
                                <v-btn @click="showDropPersistent = true" color="secondary" small rounded>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn> 
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card-title>        
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        outlined
                        dense
                        hide-details
                    ></v-text-field>

                    <v-btn @click="getCaches()" color="secondary" class="mx-2">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-switch
                        v-model="autoRefreshLogs"
                        :label="`Auto Refresh (5 sec.)`"
                    ></v-switch>
                </v-card-title>
                <v-row>
                    <v-col>        
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="caches"
                            :search="search"
                            show-expand
                            :expanded.sync="expanded"
                            item-key="key"
                            sort-by='key'
                            sort-desc
                            multi-sort
                        >
                
                            <template v-slot:item.paremeterString="{ item }">
                                {{ $utils.overflow(item.paremeterString, 100) }}
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" flat>
                                    <v-simple-table flat>
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-left">
                                                Parameter
                                            </th>
                                            <th class="text-left">
                                                Value
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(value, key) in item.parameters"
                                            :key="key"
                                            >
                                            <td>{{ key }}</td>
                                            <td>{{ value }}</td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </Dialog>
    </div>
</template>

<script>
import DeletionDialog from '../DeletionDialog.vue'
import Dialog from '../Dialog.vue'

const formatSizeString = (sizeBytes) => {
    const decimals = 2
    if (!+sizeBytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(sizeBytes) / Math.log(k))
    return `${parseFloat((sizeBytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const formatTtlString = (secondsIn) => {
    var seconds = parseInt(secondsIn, 10);

    var days = Math.floor(seconds / (3600*24));
    seconds  -= days*3600*24;
    var hrs   = Math.floor(seconds / 3600);
    seconds  -= hrs*3600;
    var mnts = Math.floor(seconds / 60);
    seconds  -= mnts*60;
    return `${days} days, ${hrs} Hrs, ${mnts} Minutes`
}

export default {
    components: { 
        Dialog,
        DeletionDialog
    },

    props: [
        "connector"
    ],

    methods: {
        doShow() {
            this.show = true
            this.getCaches()
            this.doAutoRefreshCaches()
        },

        async getCaches() {
            this.loading=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connectors/${connectorId}/caches`)
            this.caches = data
            .map(i => {return {...i, ...{
                paremeterString: JSON.stringify(i.parameters).replace('{', '').replace('}', '').replace(/:/g, "=").replace(/"/g,''),
                sizeString: formatSizeString(i.sizeBytes),
                ttlString: formatTtlString(i.ttlSeconds),
                part: i.part ? 1 + Number(i.part) : 1
            }}})
            this.cachesTotalPersitent = formatSizeString(
                this.caches
                    .filter(i => i.type === 'PersistentCache')
                    .reduce((t, i) => t + i.sizeBytes, 0)
            )
            this.cachesTotalSpeed = formatSizeString(
                this.caches
                    .filter(i => i.type === 'SpeedCache')
                    .reduce((t, i) => t + i.sizeBytes, 0)
            )
            this.cachesTotalIncremental = formatSizeString(
                this.caches
                    .filter(i => i.type === 'IncrementalCache')
                    .reduce((t, i) => t + i.sizeBytes, 0)
            )
            this.loading=false
        },

        async dropSpeedCache() {
            this.loading=true
            const connectorId = this.connector.id
            await this.$http.delete(`/connectors/${connectorId}/caches/speed`)
            this.getCaches()
            this.showDropSpeed = false
        },

        async dropIncrementalCache() {
            this.loading=true
            const connectorId = this.connector.id
            await this.$http.delete(`/connectors/${connectorId}/caches/incremental`)
            this.getCaches()
            this.showDropIncremental = false
        },

        
        async dropPersistentCache() {
            this.loading=true
            const connectorId = this.connector.id
            await this.$http.delete(`/connectors/${connectorId}/caches/persistent`)
            this.getCaches()
            this.showDropPersistent = false
        },

        async doAutoRefreshCaches() {
            // do 10 minutes
            let count = 0
            do  {               
                if(this.autoRefreshLogs) {
                     console.log('refreshed caches!');
                    this.getCaches()
                    count ++
                }                
                await new Promise(resolve => setTimeout(resolve, 5000));                
            } while (count < 120 && this.show)
        }
    },

    data: () => {
        return {
            loading: false,
            show: false,
            showDropSpeed: false,
            showDropIncremental: false,
            showDropPersistent: false,
            autoRefreshLogs: false,
            expanded: [],
            cachesTotalPersitent: 0,
            cachesTotalSpeed: 0,
            caches: [],
            search: "",
            headers: [
                { text: 'Type', value: 'type' },
                { text: 'Path', value: 'path' },
                { text: 'Parameters', value: 'paremeterString' },
                { text: 'Part', value: 'part' },
                { text: 'TTL', value: 'ttlString' },
                { text: 'Size', value: 'sizeString' }
            ]
        }
    }
}
</script>

<style scoped>
    .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    }
</style>