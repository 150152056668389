<!-- eslint-disable -->
<template>
    <div>    
        <Dialog     
            :title="`Logs: ${connector.name}`"
            :show="show"
            fullscreen
            @close="close()"
        >
            <v-card flat>
                <v-card-title>
                    <v-switch v-if="user.admin" v-model="connector.devLog" @click="putConnectorDevLogs()" label="Debug Logs">
                        Admin logs
                    </v-switch>        
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        outlined
                        dense
                        hide-details
                    ></v-text-field>
                    <v-btn @click="getLogs()" color="primary" class="mx-2">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-switch
                        v-model="autoRefreshLogs"
                        :label="`Auto Refresh (5 sec.)`"
                    ></v-switch>
                </v-card-title>
                <v-data-table
                    :loading="loading"
                    :headers="headers"
                    :items="logs"
                    :search="search"
                    sort-by='time'
                    sort-desc
                    multi-sort
                >
                    <template v-slot:item.time="{ item }">
                        {{ new Date(item.time).toLocaleString() }}
                    </template>

                    <template v-slot:item.message="{ item }">
                        {{
                            $utils.overflow(
                            item.message ? item.message : JSON.stringify(JSON.parse(item.json)),
                            100
                            )
                        }}
                    </template>

                    <template v-slot:item.show="{ item }">
                        <v-chip
                            @click="setSelection(item)"
                            color="primary"
                            dark
                        >
                            <v-icon>mdi-information-outline</v-icon>
                        </v-chip>
                    </template>
                </v-data-table>

                <v-textarea 
                    v-if="selectionMessage"
                    outlined
                    readonly
                    :value="selectionMessage"
                    label= "Message"
                    auto-grow
                    auto-focus
                />

                <v-textarea 
                    v-if="selectionJson"
                    outlined
                    readonly
                    :value="JSON.stringify(JSON.parse(selectionJson), null, 2)"
                    label= "JSON Error"
                    auto-grow
                    auto-focus
                />

                <v-textarea 
                    v-if="selectionHttp"
                    outlined
                    readonly
                    :value="JSON.stringify(JSON.parse(selectionHttp), null, 2)"
                    label= "HTTP Error"
                    auto-grow
                    auto-focus
                />

                <v-textarea 
                    v-if="selectionStack"
                    outlined
                    readonly
                    :value="selectionStack"
                    label= "Stack"
                />
            </v-card>
        </Dialog>
    </div>
</template>

<script>
import Dialog from '../Dialog.vue'

export default {
    components: { 
        Dialog
    },

    props: [
        "connector",
        "user"
    ],

    methods: {
        doShow() {
            this.show = true
            this.getLogs()
            this.doAutoRefreshLogs()
        },

        close() {
            this.show = false
            this.$emit('close')
        },

        async setSelection(item) {
            this.selectionMessage = item.message;
            this.selectionJson = item.json
            this.selectionHttp = item.http
            this.selectionStack = item.stack
        },

        async getLogs() {
            this.loading=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connectors/${connectorId}/logs?filter=${JSON.stringify({
                    where: this.connector.devLog ? null : {
                        isDev: false
                    },
                    order: 'id DESC',
                    limit: 5000
                }
            )}`)
            this.logs = data
            this.loading = false
        },

        
        async putConnectorDevLogs() {
            const connectorId = this.connector.id

            //patch it to the connector itself
            await this.$http.patch(`/connectors/${connectorId}`, {
                devLog: this.connector.devLog
            })
            //refresh log
            this.getLogs()
        },

        async doAutoRefreshLogs() {
            // do 10 minutes
            let count = 0
            do  {               
                if(this.autoRefreshLogs) {
                     console.log('refreshed logs!');
                    this.getLogs()
                    count ++
                }                
                await new Promise(resolve => setTimeout(resolve, 5000));                
            } while (count < 120 && this.show)
        },
    },

    data: () => {
        return {
            loading: false,
            show: false,
            autoRefreshLogs: false,
            logs: [],
            selectionMessage: null,
            selectionJson: null,
            selectionHttp: null,
            selectionStack: null,
            search: "",
            headers: [
                { text: 'Time', value: 'time' },
                { text: 'PID', value: 'pid' },
                { text: 'Message', value: 'message' },
                { text: 'Show', value: 'show' }
            ]
        }
    }
}
</script>

<style>

</style>