<!-- eslint-disable -->
<template>
    <div>
        <DeletionDialog
            type="Tenant"
            :name="tenant.name"
            :show="showDelete"
            @cancel="showDelete = false"
            @delete="deleteTenant(tenant)"
        >
        </DeletionDialog>

        <Dialog     
            :title="`${tenant.id === undefined ? 'Add' : 'Update'} Tenant`"
            :show="show"
            @close="show = false"
        >
            <ValidationObserver ref="tenantObserver" v-slot="{ invalid }">
                <v-card-text>        
                    <ValidationProvider name="name" rules="required"  v-slot="{ errors, valid }">   
                        <v-text-field
                            label="Name"
                            outlined
                            v-model="tenant.name"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="url" v-slot="{ errors, valid }">   
                        <v-text-field
                            label="URL"
                            outlined
                            v-model="tenantUrl"
                            :error-messages="errors"
                            :success="valid"
                            readonly
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="description" v-slot="{ errors, valid }">   
                        <v-textarea
                            label="Description"
                            outlined
                            v-model="tenant.description"
                            :error-messages="errors"
                            :success="valid"
                        ></v-textarea>
                    </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="tenant.id !== undefined" color="error" outlined @click="showDeleteTenant(tenant)">Delete</v-btn>
                    <v-btn v-if="tenant.id !== undefined" color="primary" @click="patchTenant(tenant)" :loading="loading" :disabled="invalid">Save</v-btn>
                    <v-btn v-else color="primary" @click="postTenant(tenant)" :loading="loading" :disabled="invalid">Add</v-btn>
                </v-card-actions> 
            </ValidationObserver>
        </Dialog>
    </div>
</template>

<script>
import DeletionDialog from '../DeletionDialog.vue'
import Dialog from '../Dialog.vue'

export default {
    components: { 
      Dialog,
      DeletionDialog
    },

    props: [
        "tenant",
        "user"
    ],

    computed : {
        tenantUrl() {
            return this.tenant.name.toLowerCase().replace(/ /g, "-")
        }
    },

    methods: {
        doShow() {
            this.show = true
        },

        async showDeleteTenant(connector) {
            this.connector = connector
            this.showDelete = true
        },

        async deleteTenant(tenant) {
            this.loading = true
            const tenantId = tenant.id
            await this.$http.delete(`/tenants/${tenantId}`)
            this.$emit('save')
            this.loading = false
            this.show = false
        },

        async patchTenant(tenant) {
            this.loading = true
            const tenantId = tenant.id
            const patch = {
                name: tenant.name,
                description: tenant.description,
                URL: this.tenantUrl
            }
            await this.$http.patch(`/tenants/${tenantId}`, patch)
            this.$emit('save')
            this.loading = false
            this.show = false
        },

        async postTenant(tenant) {
            this.loading = true
            tenant['URL'] = this.tenantUrl
            await this.$http.post(`/users/${this.user.id}/tenants/`, tenant)
            this.$emit('save')
            this.loading = false
            this.show = false
        }
    },

    data: () => {
        return {
            loading: false,
            show: false,
            showDelete: false
        }
    }
}
</script>

<style>

</style>