<template>
  <v-footer v-if="isDev"
    app  
    padless
    fixed
    style="padding-bottom:50px; z-index: 6;">
    <v-flex xs12 >
    <v-card
      flat
      tile
      class="text-center"
      color="green"
    > 
      <v-card-title class="text-overline pa-1">Dev Bar</v-card-title>
      <v-card-text class="pa-1">
        <v-combobox
            chips
            :items="apiServers"
            v-model="apiServerSelected"
            @change="setApiServer(apiServerSelected)"
            label="API Server"
            dense
            solo-inverted
        >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              No results Api Server. Press <kbd>enter</kbd> to create a new one
            </v-list-item-title>
          </v-list-item>
        </template>
        </v-combobox>
      </v-card-text>
    </v-card>
    </v-flex>
  </v-footer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import axios from '@/plugins/axios'

export default {

  computed: {
    ...mapState(['apiServers', 'apiServer']),
  },

  created() {
    this.apiServerSelected = this.apiServer
    axios.defaults.baseURL = this.apiServer
  },

  methods: {
    ...mapMutations(['setApiServer']),
  },

  data: () => {
    return {
      apiServerSelected: null,
      isDev: process.env.NODE_ENV !== "production"
    }
  }
};
</script>
