<!-- eslint-disable -->
<template>
  <div class="Logs">
    <!-- Page -->
    <v-card flat>
      <v-card-title> Logs </v-card-title>
      <v-card-subtitle > Shows the logs from the last 5 hours! For a larger range check the log on connector level.  </v-card-subtitle>
      <v-card-title>
        <v-switch
          v-if="user.admin"
          v-model="devLog"
          label="Debug Logs"
          @click="getLogs()"
        >
          Admin logs
        </v-switch>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>

        <v-btn @click="getLogs()" color="primary" class="mx-2">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-switch
          v-model="autoRefreshLogs"
          :label="`Auto Refresh (5 sec.)`"
        ></v-switch>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="logs"
        :search="search"
        sort-by="time"
        sort-desc
        multi-sort
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50, 100, 200],
        }"
      >
        <template v-slot:item.__tenant.name="{ item }">
          {{ $utils.overflow(item.__tenant.name, 100) }}
        </template>

        <template v-slot:item.__connector.name="{ item }">
          {{ $utils.overflow(item.__connector.name, 100) }}
        </template>

        <template v-slot:item.time="{ item }">
          {{ new Date(item.time).toLocaleString() }}
        </template>

        <template v-slot:item.message="{ item }">
          {{
            $utils.overflow(
              item.message ? item.message : JSON.stringify(JSON.parse(item.json)),
              100
            )
          }}
        </template>

        <template v-slot:item.show="{ item }">
          <v-chip @click="setSelection(item)" color="secondary" dark>
            <v-icon>mdi-information-outline</v-icon>
          </v-chip>
        </template>
      </v-data-table>

      <v-textarea
        v-if="selectionMessage"
        outlined
        readonly
        :value="selectionMessage"
        label="Message"
        auto-grow
        auto-focus
      />
      <v-textarea
        v-if="selectionJson"
        outlined
        readonly
        :value="JSON.stringify(JSON.parse(selectionJson), null, 2)"
        label="JSON Error"
        auto-grow
        auto-focus
      />

      <v-textarea
        v-if="selectionHttp"
        outlined
        readonly
        :value="JSON.stringify(JSON.parse(selectionHttp), null, 2)"
        label="HTTP Error"
        auto-grow
        auto-focus
      />
      <v-textarea
        v-if="selectionStack"
        outlined
        readonly
        :value="selectionStack"
        label="Stack"
      />
    </v-card>
  </div>
</template>

<script>
import {subSeconds} from 'date-fns'
export default {
  props: ["user"],

  beforeDestroy() {
    this.show = false;
  },

  mounted() {
    this.show = true;
    this.getLogs();
    this.doAutoRefreshLogs();
  },

  methods: {
    async setSelection(item) {
      this.selectionMessage = item.message;
      this.selectionJson = item.json;
      this.selectionHttp = item.http;
      this.selectionStack = item.stack;
    },

    async getLogs() {
      this.loading = true;
      const logs = [];
      const userId = this.user.id;
      // const filter = {
      //   include: [
      //     {
      //       relation: 'connectors',
      //       scope: {
      //         include: ['logs'],
      //         where: this.devLog ? null : {
      //           'isDev': false
      //         }
      //       },

      //     }
      //   ]
      // }
      const filter = {
        include: [
          {
            relation: "connectors",
            scope: {
              include: [
                {
                  relation: "logs",
                  scope: {
                    where: {
                      and: [                                       
                        this.devLog ? null : { isDev: false, },
                        {
                          time : {
                            gte: subSeconds(new Date(), 5 * 60 * 60) // get items till 5 hours ago
                          } 
                        }
                      ]
                    }
                  }
                },
              ],
            },
          },
        ],
      };
      const { data } = await this.$http.get(
        `/users/${userId}/tenants?filter=${encodeURIComponent(
          JSON.stringify(filter)
        )}`
      );

      for (const tenant of data) {
        if (tenant.connectors) {
          for (const connector of tenant.connectors) {
            if (connector.logs) {
              for (const log of connector.logs) {
                logs.push({ ...log, __tenant: tenant, __connector: connector });
              }
            }
          }
        }
      }
      this.logs = logs
        .sort((a, b) => a.time - b.time) // sort on created date;
      this.loading = false;
    },

    async doAutoRefreshLogs() {
      // do 10 minutes
      let count = 0;
      do {
        if (this.autoRefreshLogs) {
          console.log("refreshed logs!");
          this.getLogs();
          count++;
        }
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (count < 120 && this.show);
    },
  },

  data: () => {
    return {
      show: false,
      loading: false,
      autoRefreshLogs: false,
      logs: [],
      selectionMessage: null,
      selectionJson: null,
      selectionHttp: null,
      selectionStack: null,
      devLog: false,
      search: "",
      headers: [
        { text: "Tenant", value: "__tenant.name" },
        { text: "Connector", value: "__connector.name" },
        { text: "Time", value: "time" },
        { text: "PID", value: "pid" },
        { text: "Message", value: "message" },
        { text: "Show", value: "show" },
      ],
    };
  },
};
</script>

<style></style>
