import Vue from 'vue'
import VueRouter from 'vue-router'
import Connectors from '../views/Connectors.vue'
import Tenants from '../views/Tenants.vue'
import Logs from '../views/Logs.vue'
import Requests from '../views/Requests.vue'
import Jobs from '../views/Jobs.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      path: '/connectors',
    },
  },
  {
    path: '/connectors',
    name: 'Connectors',
    component: Connectors
  },
  {
    path: '/tenants',
    name: 'Tenants',
    component: Tenants
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/requests',
    name: 'Requests',
    component: Requests
  },
  {
    path: '/jobs-queue',
    name: 'Jobs',
    component: Jobs
  }
]

const router = new VueRouter({
  routes
})

export default router
