<!-- eslint-disable -->
<template>
  <div>
    <Dialog
      :title="`Add Connectors in Bulk`"
      :show="show"
      @close="show = false"
      fullscreen
    >
      <ValidationObserver ref="connector-bulk" v-slot="{ invalid }">
        <v-card-title class="subtitle-2"
          >Download the template file and upload connectors in
          bulk</v-card-title
        >
        <a
          :href="`${apiServer}/bulktemplate_cleversight.csv`"
          target="_blank"
          download
        >
          <v-btn color="primary" class="mx-2">
            <v-icon class="mr-2">mdi-file-download-outline </v-icon>
            <span>Template file</span></v-btn
          >
        </a>
        <ValidationProvider
          name="version"
          rules="required|csvValidator"
          v-slot="{ errors, valid }"
        >
          <v-file-input
            accept=".csv"
            truncate-length="15"
            @change="handleFileChange"
            :error-messages="errors"
            :success="valid"
            v-model="fileUpload"
            style="width: 400px"
          ></v-file-input>
        </ValidationProvider>
        <template>
          <v-data-table
            :headers="tableHeaders"
            :items="fileDataTransformed"
            :width="100"
            item-key="bulk-connectors-table"
          >
            <template v-slot:item.description="{ item }">
              {{ $utils.overflow(item.description, 100) }}
            </template>

            <template v-slot:item.auth="{ item }">
              <div v-for="(value, key) in item.auth" :key="key">
                {{ key }}: {{ value }}
              </div>
            </template>

            <template v-slot:item.parameters="{ item }">
              <div v-for="(value, key) in item.parameters" :key="key">
                {{ key }}: {{ value }}
              </div>
            </template>
          </v-data-table>
        </template>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="postConnectors(fileDataTransformed)"
            :loading="loading"
            :disabled="invalid"
            >Create Connectors</v-btn
          >
        </v-card-actions>
      </ValidationObserver>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "../Dialog.vue";
import Papaparse from "../../plugins/papaparse";
import { mapState } from "vuex";

export default {
  components: {
    Dialog,
  },

  props: ["tenants", "clients"],

  computed: {
    ...mapState(["apiServer"])
  },

  created() {
        this.init()
    },

  methods: {
    init(){
      this.data = "No File Chosen";
      this.fileData = null;
      this.fileUpload = null;
      this.fileDataTransformed = [];
      this.parsedData = null;
    },
    doShow() {
      this.show = true;
    },
    handleFileChange(event) {
      // When file gets deleted, it should not continue!
      if (!event || !event.name) {
        this.data = "No File Chosen";
        this.fileDataTransformed = [];
        return;
      }
      let type = event.type;
      if (type != "text/csv") {
        window.snackbar.queue.push({
          color: "error",
          text: "The uploaded file is not a csv, please upload the correct filetype!",
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsText(event);
      reader.onload = () => {
        this.fileData = reader.result;
        this.parseFile(this.fileData);
      };
    },
    async parseFile(fileData) {
      this.transformData(await Papaparse(fileData));
    },
    async transformData(parsedData) {
      if (parsedData.length <= 0) {
        window.snackbar.queue.push({
          color: "error",
          text: "Invalid or empty parsed data!",
        });
        return [];
      }

      // Validation
      if (!this.validateHeaders(Object.keys(parsedData[0]))) {
        return [];
      }

      let transformedData = [];

      for (const row of parsedData) {
        let newObject = await this.transformRow(row);
        transformedData.push(newObject);
      }

      this.fileDataTransformed = transformedData ;
    },
    async validateHeaders(headers){
        const validHeaders = [
          "tenantId",
          "tenantName",
          "name",
          "description",
          "client",
          "clientVersion",
          "auth",
          "parameters",
        ];
        const headersWithoutSub = headers.map((i) => i.split(".")[0]);
        if (headersWithoutSub.some((i) => !validHeaders.includes(i))) {
          const diff = headersWithoutSub.filter(
            (i) => !validHeaders.includes(i)
          );
          window.snackbar.queue.push({
          color: "error",
          text: `Invalid headers in csv, check headers: ${diff.join(",")}!`,
        });
          return false;
        } else {
          return true;
        }},
    async transformRow(row) {
    let newObject = {
        tenantId: {},
        tenantName: {},
        name: {},
        description: {},
        client: {},
        clientVersion: {},
        auth: {},
        parameters: {}
    };

    for (const [key, value] of Object.entries(row)) {
        if (key.includes(".")) {
            const [envVariable, envVariableSub] = key.split(".");
            if (!newObject[envVariable]) newObject[envVariable] = {};
            let finalValue = value;

            if (typeof value === 'string' && value.trim().startsWith("[") && value.trim().endsWith("]")) {
                try {
                    finalValue = JSON.parse(value);
                } catch (error) {
                    console.error(`Failed to parse value for key: ${key}, error: ${error}`);
                }
            }

            newObject[envVariable][envVariableSub] = finalValue;
        } else {
            newObject[key] = value;
        }
    }
    return newObject;
    },
    async postConnectors(fileDataTransformed) {
      const connectors = JSON.parse(JSON.stringify(fileDataTransformed))
      for (const item of connectors) {
        if(item.tenantName){
          delete item.tenantName
        }
      }

      this.loading = true;
      try {
        await this.$http.post(`/connectors/batch`, connectors);
        window.snackbar.queue.push({
          color: "success",
          text: "Succesfully, created the connectors!",
        });
        this.show = false;
        this.$emit("save")
        // eslint-disable-next-line
      } catch (err){}

      this.loading = false;
    },
  },

  data: () => {
    return {
      loading: false,
      show: false,
      connector: false,
      showDelete: false,
      fileUpload: null,
      fileData: null,
      fileDataTransformed: [],
      tableHeaders: [
        { text: "Tenant Id", value: "tenantId", width: "120px" },
        { text: "Tenant Name", value: "tenantName", width: "120px" },
        { text: "Connector Name", value: "name", width: "100px" },
        { text: "Description", value: "description", width: "120px" },
        { text: "Client", value: "client" },
        { text: "Client Version", value: "clientVersion" },
        { text: "Auth", value: "auth" },
        { text: "Parameters", value: "parameters" },
      ],
    };
  },
};
</script>

<style></style>
