import { required, email, max, min, mimes } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("max", {
  ...max,
  message: "This field must be {length} characters or less"
});

extend("min", {
    ...min,
    message: "This field must be {length} characters or more"
  });

extend("email", {
  ...email,
  message: "This field must be a valid email"
});

extend("mimes", {
  ...mimes,
  message: "This field must be a valid filetype"
});

extend("json", {
  getMessage: () => `This field must be a valid JSON`,
  validate: (value) =>  {
    console.log({value});
    try {
      JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
  }
});

const MOBILEREG = /\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
extend('phone', {
  getMessage: () => `This field must be a valid Phone number`,
  validate: (value) =>  {
    console.log({value});
    try {
      return MOBILEREG.test(value)
    } catch (e) {
        return false;
    }
  }
});

var PASSWORDREG = /^.*(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[$@$!%*#?&]).*$/
extend("passwordreg", {
  getMessage: () => `This field must be a valid password`,
  validate: (value) =>  {
    console.log({value});
    try {
      return PASSWORDREG.test(value)
    } catch (e) {
        return false;
    }
  }
});

extend('csvValidator', {
  getMessage: () => `This field must be a csv`,
  validate: (value) => {
    let type = value.type
    if(type != "text/csv"){
        return false
    } else {
        return true
    }
}});