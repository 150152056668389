<template>
  <div>
    <!-- update /delete -->
    <user-profile-dialog 
      :key="`${user.id}_${$utils.uuid()}`"
      :user="JSON.parse(JSON.stringify(user))" 
      @save="safeUserProfile()"
      ref="userProfileDialog"      
    ></user-profile-dialog >

    <v-app-bar
      app
      absolute
      min-height="64"
      max-height="64"
      >
      <v-img 
        src="@/assets/logo-horizontal-small.svg"
        max-height="60"
        max-width="180"
      ></v-img>
      <v-divider  class="mx-2" vertical inset></v-divider>
      <v-toolbar-title class="font-weight-bold">Data Warehouse</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y origin="center center" :nudge-bottom="10" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon large text v-on="on">
            <v-avatar size="40px">
              <img src="images/default_avatar.png"/>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>     
          <v-list-item avatar @click="openUserProfile(user)">
            <v-list-item-avatar>
              <img src="images/default_avatar.png">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> {{ user.firstName }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item v-if="user.admin" @click="$refs.generalSettings.show = true" ripple="ripple">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Admin Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>      
          <v-divider></v-divider>
          <v-list-item @click="unsetAccessToken()" ripple="ripple">
            <v-list-item-action>
              <v-icon>forward</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
  import { mapMutations } from 'vuex'
  import UserProfileDialog from '../components/user/UserProfileDialog.vue'

  export default {

    props: {
      user : Object
    },

    components: { UserProfileDialog},
    
    methods: {
      ...mapMutations(['unsetAccessToken']),

      safeUserProfile() {
        this.$emit('save')
      },

      openUserProfile() {
          this.$refs.userProfileDialog.doShow();
      }
    }
  }
</script>
