<!-- eslint-disable -->
<template>
    <div>
        <Dialog     
            :title="`Requests: ${connector.name}`"
            :show="show"
            fullscreen
            @close="show = false"
        >
            <v-card flat>
                 <v-card-title>        
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        outlined
                        dense
                        hide-details
                    ></v-text-field>

                    <v-btn @click="getRequests()" color="secondary" class="mx-2">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-switch
                        v-model="autoRefreshRequests"
                        :label="`Auto Refresh (5 sec.)`"
                    ></v-switch>
                </v-card-title>
                <v-row>
                    <v-col>        
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="requests"
                            :search="search"
                            show-expand
                            :expanded.sync="expanded"
                            single-expand
                            item-key="id"
                            sort-by='id'
                            sort-desc
                            multi-sort   
                        >
                
                            <template v-slot:item.created="{ item }">
                                {{ new Date(item.created).toLocaleString() }}
                            </template>

                            <template v-slot:item.paremeterString="{ item }">
                                {{ $utils.overflow(item.paremeterString, 100) }}
                            </template>

                            <template v-slot:item.url="{ item }">
                                {{ $utils.overflow(item.url, 100) }}
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-card outlined>
                                        <v-card-title>
                                        URL
                                        </v-card-title>
                                        <v-card-text>
                                            {{ item.url }}
                                        </v-card-text>
                        
                                        
                                        <v-card-title>
                                        Parameters
                                        </v-card-title>
                                        <v-simple-table flat>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th class="text-left">
                                                    Parameter
                                                </th>
                                                <th class="text-left">
                                                    Value
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(value, key) in item.parameters"
                                                :key="key"
                                                >
                                                <td>{{ key }}</td>
                                                <td>{{ value }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                  </v-col>
                              </v-row>
                            </v-container>
                          </td>
                          </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </Dialog>
    </div>
</template>

<script>
// import DeletionDialog from '../DeletionDialog.vue'
import Dialog from '../Dialog.vue'

export default {
    components: { 
        Dialog,
        // DeletionDialog,
    },

    props: [
        "connector"
    ],

    methods: {
        doShow() {
            this.show = true
            this.getRequests()
            this.doAutoRefreshRequests()
        },

        async getRequests() {
            this.loading=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connectors/${connectorId}/requests?filter=${JSON.stringify({
                limit : 500,
                order: 'id DESC',
            })}`)

            this.requests = data.map(i => {
                return {...i, ...{
                paremeterString: i.parameters ? JSON.stringify(i.parameters).replace('{', '').replace('}', '').replace(/:/g, "=").replace(/"/g,'') : "",
            }}})

            this.loading=false
        },

        async doAutoRefreshRequests() {
            // do 10 minutes
            let count = 0
            do  {               
                if(this.autoRefreshRequests) {
                     console.log('refreshed usages!');
                    this.getRequests()
                    count ++
                }                
                await new Promise(resolve => setTimeout(resolve, 5000));                
            } while (count < 120 && this.show)
        }
    },

    data: () => {
        return {
            loading: false,
            show: false,
            autoRefreshRequests: false,
            expanded: [],
            requests: [],
            search: "",
            headers: [
                { text: 'Date', value: 'created' },
                { text: 'Path', value: 'path' },
                { text: 'Parameters', value: 'paremeterString' },
                { text: 'Url', value: 'url'}
            ]
        }
    }
}
</script>

<style scoped>
    .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none;
    }
</style>