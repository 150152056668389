<!-- eslint-disable -->
<template>
    <Dialog     
        title="Info Connector"
        :show="show"
        @close="show = false"
    >
        <v-text-field
            label="ID"
            readonly
            outlined
            :value="connector.id"
            append-icon="mdi-content-copy"
            @click:append="copyText(connector.id);"
        ></v-text-field>
        <v-text-field
            label="URL"
            readonly
            outlined
            :value="link"
            append-icon="mdi-content-copy"
            @click:append="copyText(link)"
        ></v-text-field>
    </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import Dialog from '../Dialog.vue'

export default {
    components: { 
      Dialog
    },

    props: [
        "connector"
    ],

    computed: {
        ...mapState(['apiServer']),
        link : function() {
            return `${this.apiServer}/${this.connector.id}/odata`
        }
    },

    methods: {
        doShow() {
            this.show = true
        },
        copyText(text) {
            console.info('Copied to clipboard');
            navigator.clipboard.writeText(text);
            window.snackbar.queue.push({
                color: "success",
                text: "Copied item to clipboard!",
            });
        }
    },

    data: () => {
        return {
            show: false
        }
    }
}
</script>

<style>

</style>