<!-- eslint-disable -->
<template>
    <Dialog     
        title="Authorize Connector"
        :show="show"
        @close="show = false"
    >

        <template v-if="connector.__client && connector.__client.authentication">
            <!-- Anonymous -->
            <template v-if="connector.__client.authentication.type === 'anonymous'">
                Probably you're good to go!
            </template>

            <!-- Basic -->
            <template v-if="connector.__client.authentication.type === 'basic'">
                <ValidationObserver ref="basicAuth" v-slot="{ invalid }">
                    <ValidationProvider name="username" rules="required"  v-slot="{ errors, valid }">   
                        <v-text-field
                            label="Username"
                            outlined
                            v-model="connector.auth.username"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="password" rules="required"  v-slot="{ errors, valid }"> 
                        <v-text-field
                            label="Password"
                            outlined
                            v-model="connector.auth.password"
                            type="password"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="putConnector(connector)" :loading="loading" :disabled="invalid">Save</v-btn>
                </ValidationObserver>
            </template>

            <!-- oauthImplicit -->
            <template v-if="connector.__client.authentication.type === 'oauthImplicit'">
                <ValidationObserver ref="basicAuth" v-slot="{ invalid }">
                    <ValidationProvider name="clientId" rules="required"  v-slot="{ errors, valid }">
                        <v-text-field
                            label="Client Id"
                            outlined
                            type="password"
                            v-model="connector.auth.client_id"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider name="clientSecret" rules="required"  v-slot="{ errors, valid }">
                        <v-text-field
                            label="Client Secret"
                            outlined
                            type="password"
                            v-model="connector.auth.client_secret"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <v-spacer></v-spacer>
                    <v-btn  color="primary" @click="putConnector(connector)" :loading="loading" :disabled="invalid">Save</v-btn>
                </ValidationObserver>            
            </template>

            <!-- Token -->
            <template v-if="connector.__client.authentication.type === 'accessToken'">
                <ValidationObserver ref="basicAuth" v-slot="{ invalid }">
                    <ValidationProvider name="username" rules="required"  v-slot="{ errors, valid }">
                        <v-text-field
                            label="Access Token"
                            outlined
                            type="password"
                            v-model="connector.auth.access_token"
                            :error-messages="errors"
                            :success="valid"
                        ></v-text-field>
                    </ValidationProvider>
                    <v-spacer></v-spacer>
                    <v-btn  color="primary" @click="putConnector(connector)" :loading="loading" :disabled="invalid">Save</v-btn>
                </ValidationObserver>            
            </template>
            
            <!-- Oauth -->
            <template v-if="connector.__client.authentication.type === 'oauth'">
                <v-text-field
                    v-if="connector.auth.token"
                    label="Access Token"
                    readonly
                    outlined
                    :value="connector.auth.token.access_token"
                ></v-text-field>
                <v-text-field
                    v-if="connector.auth.token"
                    label="Expires"
                    readonly
                    outlined
                    :value="connector.auth.token.expires_at"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showAuthorizePopup(connector.id)">(re)Authorize</v-btn>
            </template>

            <!-- Token -->
            <template v-if="connector.__client.authentication.type === 'oauthSnelStart'">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showAuthorizePopup(connector.id)">(re)Authorize</v-btn>          
            </template>
        </template>
        <template v-else>
            The choosen connector has not authentication defined!
        </template>
    </Dialog>
</template>

<script>
import Dialog from '../Dialog.vue'

export default {
    components: { 
      Dialog
    },

    props: [
        "connector"
    ],

    methods: {
        doShow() {
            this.show = true
        },

        async putConnector(connector) {
            this.loading = true
            const connectorId = connector.id
            //delete if
            await this.$http.patch(`/connectors/${connectorId}`, {
                auth: connector.auth
            })
            this.$emit('save')
            this.loading = false
            this.show = false
        },
        
        async showAuthorizePopup(connectorId)
        {  
            const {data} = await this.$http.get(`/connectors/${connectorId}/oauth/build-authorize-url`)
            const {authorizeUrl, success} = data
            if(success) {
                const popup = await window.open(authorizeUrl, 'authorize', 'fullscreen="yes"')
                if (popup == null) { alert('Please check your popup settings')}
                else {
                    popup.moveTo(0, 0);
                    popup.resizeTo(screen.width, screen.height);
                }
            }
        } 
    },

    data: () => {
        return {
            loading: false,
            show: false
        }
    }
}
</script>

<style>

</style>