<!-- eslint-disable -->
<template>
  <div class="Connectors">
    <!-- Info -->
    <connector-info-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      :connector="connector"
      ref="infoConnectorDialog"
    ></connector-info-dialog>

    <!-- Log -->
    <connector-log-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      @close="getConnectors()"
      :connector="connector"
      :user="user"
      ref="logConnectorDialog"
    ></connector-log-dialog>

    <DeletionDialog
      type="Connectors"
      name="Multiple Connectors"
      :show="showDelete"
      @cancel="showDelete = false"
      @delete="deleteConnectors(selected)"
        >
    </DeletionDialog>

    <!-- Request -->
    <connector-request-overview-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      :connector="connector"
      ref="requestOverviewConnectorDialog"
    ></connector-request-overview-dialog>

    <!-- Caches -->
    <connector-cache-overview-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      :connector="connector"
      ref="cacheOverviewConnectorDialog"
    ></connector-cache-overview-dialog>

    <!-- Usages -->
    <connector-usage-overview-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      :connector="connector"
      ref="usageOverviewConnectorDialog"
    ></connector-usage-overview-dialog>

    <!-- Storage -->
    <connector-storage-overview-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      :connector="connector"
      ref="storageOverviewConnectorDialog"
    ></connector-storage-overview-dialog>

    <!-- authorize -->
    <connector-authorize-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      @save="getConnectors()"
      :connector="connector"
      ref="authorizeConnectorDialog"
    ></connector-authorize-dialog>

    <!-- add / update /delete -->
    <connector-dialog
      :key="`${connector.id}_${$utils.uuid()}`"
      @save="getConnectors()"
      :connector="connector"
      :tenants="tenants"
      :clients="clients"
      ref="connectorDialog"
    ></connector-dialog>

    <!-- add bulk / -->
    <connector-dialog-bulk
      :key="`${connector.id}_${$utils.uuid()}`"
      @save="getConnectors()"
      :connector="connector"
      :tenants="tenants"
      :clients="clients"
      ref="connectorDialogBulk"
    ></connector-dialog-bulk>

    <!-- Page -->
    <v-card  style="padding-top:80px;" flat>
      <v-card-title> Connectors </v-card-title>
      <v-card-title>
        <v-btn @click="openDialog()" color="primary" class="mx-2">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn v-if="user.admin" @click="openDialogBulk()" color="primary" class="mx-2">
          <v-icon>mdi-plus-box-multiple-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="selected.length > 0"
          @click="showDeleteConnector(selected)"
          color="secondary"
          class="mx-2"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          outlined
          dense
          hide-details
          :style="{ maxWidth: '600px' }"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="connectors"
        :search="search"
        sort-by="__tenant.name"
        multi-sort
        show-select
        v-model="selected"
        :custom-filter="customSearch"
      >
        <template v-slot:item.name="{ item }">
          {{ $utils.overflow(item.name, 35) }}
          <v-btn @click="openInfoDialog(item)" icon x-small>
            <v-icon> mdi-link-variant </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.description="{ item }">
          {{ $utils.overflow(item.description, 35) }}
        </template>

        <template v-slot:item.authenticated="{ item }">
          <v-chip
            @click="openAuthorizeDialog(item)"
            :color="authenticateStatus(authenticateType(item), item).color"
            dark
          >
            <v-icon
              >{{ authenticateStatus(authenticateType(item), item).icon }}
            </v-icon>
          </v-chip>
        </template>

        <template v-slot:item.active="{ item }">
          <v-chip
            :style="user.admin ? '' : 'pointer-events: none'"
            @click="changeActiveConnector(item)"
            :color="activeStatus(item).color"
            dark
          >
            <v-icon>{{ activeStatus(item).icon }} </v-icon>
          </v-chip>
        </template>

        <template v-slot:item.usage="{ item }">
          <div class="d-flex">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="primary" v-on="on" v-bind="attrs">
                  <v-icon>mdi-speedometer </v-icon>
                </v-chip>
              </template>
              <v-list>
                <v-list-item @click="openRequestOverviewDialog(item)">
                  <v-list-item-title>Requests</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openUsageOverviewDialog(item)">
                  <v-list-item-title>Usage Overview</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openCacheOverviewDialog(item)">
                  <v-list-item-title>Cache</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openStorageOverviewDialog(item)">
                  <v-list-item-title>Storage</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>

        <template v-slot:item.logs="{ item }">
          <v-chip @click="openLogDialog(item)" color="secondary" dark>
            <v-icon>mdi-android </v-icon>
          </v-chip>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-chip @click="openDialog(item)" color="secondary" dark>
            <v-icon>mdi-circle-edit-outline</v-icon>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ConnectorAuthorizeDialog from "../components/connectors/ConnectorAuthorizeDialog.vue";
import ConnectorDialog from "../components/connectors/ConnectorDialog.vue";
import ConnectorInfoDialog from "../components/connectors/ConnectorInfoDialog.vue";
import ConnectorLogDialog from "../components/connectors/ConnectorLogDialog.vue";
import ConnectorCacheOverviewDialog from "../components/connectors/ConnectorCacheOverviewDialog.vue";
import ConnectorUsageOverviewDialog from "../components/connectors/ConnectorUsageOverviewDialog.vue";
import ConnectorRequestOverviewDialog from "../components/connectors/ConnectorRequestOverviewDialog.vue";
import ConnectorStorageOverviewDialog from "../components/connectors/ConnectorStorageOverviewDialog.vue";
import ConnectorDialogBulk from "../components/connectors/ConnectorDialogBulk.vue";
import DeletionDialog from '../components/DeletionDialog.vue'

export default {
  name: "Connectors",

  props: ["user", "tenants"],

  components: {
    ConnectorAuthorizeDialog,
    ConnectorDialog,
    ConnectorInfoDialog,
    ConnectorLogDialog,
    ConnectorCacheOverviewDialog,
    ConnectorUsageOverviewDialog,
    ConnectorRequestOverviewDialog,
    ConnectorStorageOverviewDialog,
    ConnectorDialogBulk,
    DeletionDialog
  },

  async mounted() {
    this.loading = true;
    await this.getClients();
    await this.getConnectors();
    this.loading = false;
  },

  methods: {
    customSearch(value, search, item) {
      return JSON.stringify(Object.values(item))
        .toLowerCase()
        .includes(search.toLowerCase());
    },

    async getClients() {
      const { data } = await this.$http.get(
        `/datasource/clients?select=authentication, parameters`
      );
      this.clients = data;
    },

    async getConnectors() {
      const connectors = [];
      const userId = this.user.id;
      if(userId) {
        const { data } = await this.$http.get(
          `/users/${userId}/tenants?filter[include][][relation]=connectors`
        );
        for (const tenant of data) {
          if (tenant.connectors) {
            for (const connector of tenant.connectors) {
              // add tenant and client as a hidden field
              const client = this.clients[String(connector.client)]
                ? this.clients[String(connector.client)][
                    String(connector.clientVersion)
                  ]
                : null;
              connectors.push({
                ...connector,
                __tenant: tenant,
                __client: client,
              });
            }
          }
        }
      }

      this.connectors = connectors;
    },

    authenticateType(item) {
      const { __client } = item;
      if (__client) {
        const { authentication } = __client;
        if (authentication) {
          const { type } = authentication;
          return type;
        }
      }
    },

    authenticateStatus(type, item) {
      const { auth } = item;
      if (!type) {
        return {
          color: "info",
          icon: "mdi-help-circle",
        };
      }

      // if basic not set
      if (type === "basic") {
        if (auth.username == null || auth.password == null) {
          return {
            color: "warning",
            icon: "mdi-emoticon-sad",
          };
        }
      }

      // if oauthImplicit not set
      if (type === "oauthImplicit") {
        if (auth.client_id == null || auth.client_secret == null) {
          return {
            color: "warning",
            icon: "mdi-emoticon-sad",
          };
        }
      }

      // if token or oauth not set
      if (type === "accessToken") {
        if (auth.access_token == null) {
          return {
            color: "warning",
            icon: "mdi-emoticon-sad",
          };
        }
      }
      // token
      if (type === "oauth") {
        if (auth.token == null || auth.token.access_token == null) {
          return {
            color: "warning",
            icon: "mdi-emoticon-sad",
          };
        }
      }

      // Other
      return {
        color: "success",
        icon: "mdi-emoticon-happy",
      };
    },

    activeStatus(item) {
      const { active } = item;
      if (active === false) {
        return {
          color: "error",
          icon: "mdi-emoticon-sad",
        };
      }
      return {
        color: "success",
        icon: "mdi-emoticon-happy",
      };
    },

    activeLoggingStatus(item) {
      const { devLog } = item;
      if (devLog === true) {
        return {
          color: "success",
        };
      }
      return {
        color: "primary",
      };
    },

    async changeActiveConnector(item) {
      this.loading = true;
      const connectorId = item.id;
      const patch = {
        active: typeof item.active === "undefined" ? false : !item.active, // change it form undefined/false to true and visa versa
      };
      await this.$http.patch(`/connectors/${connectorId}`, patch);
      this.getConnectors();
      this.loading = false;
    },

    async changeLogConnector(item) {
      this.loading = true;
      const connectorId = item.id;
      const patch = {
        devLog: typeof item.devLog === "undefined" ? false : !item.devLog, // change it form undefined/false to true and visa versa
      };
      await this.$http.patch(`/connectors/${connectorId}`, patch);
      this.getConnectors();
      this.loading = false;
    },

    openAuthorizeDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.authorizeConnectorDialog.doShow();
      });
    },

    openInfoDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.infoConnectorDialog.doShow();
      });
    },

    openLogDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.logConnectorDialog.doShow();
      });
    },

    openRequestDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.requestConnectorDialog.doShow();
      });
    },

    openRequestOverviewDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.requestOverviewConnectorDialog.doShow();
      });
    },

    openStorageOverviewDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.storageOverviewConnectorDialog.doShow();
      });
    },

    openCacheOverviewDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.cacheOverviewConnectorDialog.doShow();
      });
    },

    openUsageOverviewDialog(connector = false) {
      this.connector = JSON.parse(JSON.stringify(connector));
      this.$nextTick(() => {
        this.$refs.usageOverviewConnectorDialog.doShow();
      });
    },

    openDialog(connector = false) {
      this.connector = connector
        ? JSON.parse(JSON.stringify(connector))
        : {
            tenantId: null,
            client: null,
            clientVersion: null,
            name: "",
            description: "",
            active: this.user.admin, // true if admin otherwise false
            parameters: {},
          };
      this.$nextTick(() => {
        this.$refs.connectorDialog.doShow();
      });
    },

    async deleteConnectors(selectedConnectors) {
            this.loading = true
            let connectorIds = selectedConnectors.map((connector) => connector.id) 
            try{
              await this.$http.delete('/connectors/batch', { data: connectorIds })
                window.snackbar.queue.push({
                    color: 'success',
                    text: "Succesfully, deleted the connectors!",
                })
                this.selected = []
            } catch(err){
                window.snackbar.queue.push({
                    color: 'error',
                    text: "Error, deleteling the connectors!",
                }) 
            }
            this.showDelete = false
            await this.getConnectors();
            this.loading = false
        },

    async showDeleteConnector(){
        this.showDelete = true
    },

    openDialogBulk() {
      this.$nextTick(() => {
        this.$refs.connectorDialogBulk.doShow();
      });
    },
  },

  data: () => {
    return {
      loading: false,
      clients: {},
      connectors: [],
      connector: false,
      showDelete: false,
      search: "",
      selected: [],
      headers: [
        {
          text: "Tenant",
          value: "__tenant.name",
        },
        { text: "Name", value: "name" },
        { text: "Description", value: "description" },
        { text: "Client", value: "client" },
        { text: "Version", value: "clientVersion" },
        { text: "Authenticated", value: "authenticated" },
        { text: "Active", value: "active" },
        { text: "Usage", value: "usage" },
        { text: "Logs", value: "logs" },
        { text: "Edit", value: "edit" },
      ],
    };
  },
};
</script>
