import { v4 as uuid } from 'uuid'

/*
* Util for waiting timout promised in async functions
*/
const promisedTimeout = async(ms = 1000) => {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms);
    });
 }

const overflow = (text, length) => {
    if(text.length > length) {
        return text.substring(0, length) + "..."
    }
    return text
}

export default {
    uuid,
    overflow,
    promisedTimeout
}