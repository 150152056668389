import papa from 'papaparse'

const config = {
    delimiter: ",",
    newline: "",
    quoteChar: '"',
    escapeChar: '"',
    header: true,
    skipEmptyLines: true,
}

const Papaparse = async (file) => {
    let parsedCsvToJsonFile = papa.parse(file, config)
    return parsedCsvToJsonFile.data
}


   export default Papaparse

