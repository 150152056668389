<!-- eslint-disable -->
<template>
  <div class="Requests">
    <v-row>
      <v-col>
        <v-card-title> Requests</v-card-title>
        <v-card-subtitle > Shows the requests (from the last 10 minutes)! For a larger range check the request on connector level. </v-card-subtitle>
              

      </v-col>
      <v-col cols="2">
        <v-switch
            v-model="autoRefreshRequests"
            :label="`Auto Refresh (5 sec.)`"
        ></v-switch>
      </v-col>
    </v-row>
          <v-card flat>
              <v-card-title>        
                  <v-spacer></v-spacer>
                  <v-text-field
                      v-model="searchRequest"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      outlined
                      dense
                      hide-details
                  ></v-text-field>
                  <v-btn @click="getRequests()" color="secondary" class="mx-2">
                      <v-icon>refresh</v-icon>
                  </v-btn>
 
              </v-card-title>
              <v-row>
                  <v-col>        
                      <v-data-table
                          :loading="loading"
                          :headers="headersRequests"
                          :search="searchRequest"
                          show-expand
                          :expanded.sync="expanded"
                          single-expand
                          :items="requests"
                          item-key="id"
                          sort-by='id'
                          sort-desc
                          multi-sort
                          :items-per-page="25"                         
                      >
              
                          <template v-slot:item.created="{ item }">
                              {{ new Date(item.created).toLocaleString() }}
                          </template>

                          <template v-slot:item.paremeterString="{ item }">
                              {{ $utils.overflow(item.paremeterString, 100) }}
                          </template>
                          <template v-slot:item.url="{ item }">
                              {{ $utils.overflow(item.url, 100) }}
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <v-container>
                                <v-row>
                                  <v-col>
                                    <v-card outlined>
                                        <v-card-title>
                                        URL
                                        </v-card-title>
                                        <v-card-text>
                                            {{ item.url }}
                                        </v-card-text>
                        
                                        
                                        <v-card-title>
                                        Parameters
                                        </v-card-title>
                                        <v-simple-table flat>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th class="text-left">
                                                    Parameter
                                                </th>
                                                <th class="text-left">
                                                    Value
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(value, key) in item.parameters"
                                                :key="key"
                                                >
                                                <td>{{ key }}</td>
                                                <td>{{ value }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                  </v-col>
                              </v-row>
                            </v-container>
                          </td>
                          </template>
                      </v-data-table>
                  </v-col>
              </v-row>
          </v-card>
  </div>
</template>

<script>
import {subSeconds} from 'date-fns'


export default {
  props: ["user"],

  beforeDestroy() {
    this.show = false
  },

  mounted() {
      this.show = true
      this.getClients();
      this.getRequests();
      this.doAutoRefreshRequests();
  },

  methods: {

    async getClients() {
      const { data } = await this.$http.get(
        `/datasource/clients?select=authentication, parameters`
      );
      this.clients = data;
    },

    async getRequests() {
      this.loading = true
      const requests = []
      const userId = this.user.id
      const filter = {
        include: [
          {
            relation: 'connectors',
            scope: {
              include: [
                {
                  relation: 'requests',
                  scope: {
                    where: {
                      created : {
                        gte: subSeconds(new Date(), 10 * 60) // get items till 10 minutes ago
                      } 
                    },
                  }
                }
              ],       
            },
          }
        ]
      }
      const {data} = await this.$http.get(`/users/${userId}/tenants?filter=${encodeURIComponent(JSON.stringify(filter))}`)
      //console.log(data)
      for(const tenant of data) {
        if(tenant.connectors) {
          for(const connector of tenant.connectors) {
            if(connector.requests) {
              //console.log(connector.requests)
              for(const request of connector.requests) {
                 requests.push({...request, __tenant : tenant, __connector : connector})
              }
            }
          }
        }
      }
      this.requests = requests
          .sort((a, b) => a.created - b.created) // sort on created date
          .map(i => {
                return {...i, ...{
                paremeterString: i.parameters ? JSON.stringify(i.parameters).replace('{', '').replace('}', '').replace(/:/g, "=").replace(/"/g,'') : "",
            }}})
      console.log({requests});
      this.loading = false
    },

    async doAutoRefreshRequests() {
      // do 10 minutes
      let count = 0;
      do {
        if (this.autoRefreshLogs) {
          console.log("refreshed logs!");
          this.getRequests();
          this.getLocks()
          count++;
        }
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (count < 120 && this.show);
    },

  },

  data: () => {
      return {
          loading: false,
          show: false,
          autoRefreshRequests: false,
          clients: {},
          caches: [],
          expanded: [],
          requests: [],
          searchRequest: "",
          headersRequests: [
              { text: "Tenant", value: "__tenant.name" },
              { text: "Connector", value: "__connector.name" },
              { text: 'Date', value: 'created' },
              { text: 'Path', value: 'path' }, 
              { text: 'Parameters', value: 'paremeterString' },
              { text: 'Url', value: 'url'},
          ]
      }
  }
}
</script>

<style scoped>
  .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  }
</style>