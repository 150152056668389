<!-- eslint-disable -->
<template>
    <div>
        <DeletionDialog
            type="user"
            :name="userDelete.firstName"
            :show="showDelete"
            @cancel="showDelete = false"
            @delete="deleteUser(userDelete)"
        >
        </DeletionDialog>

        <!-- add / update /delete users to tenant -->
        <tenant-add-user-dialog 
            :key="`${tenant.id}_${$utils.uuid()}`"
            @save="getTenantUsers()" 
            :tenant="tenant"
            :user="user"
            ref="tenantAddUserDialog"
        ></tenant-add-user-dialog>


        <Dialog     
            :title="`Manage users: ${tenant.name}`"
            :show="show"
            @close="show = false"
        >

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showAddUserTenant()"><v-icon>mdi-account-plus</v-icon> Invite User</v-btn>
            </v-card-actions> 

            <v-card-text>  
                <v-container class="pa-1">
                    <v-item-group            >
                        <v-row>
                        <v-col
                            v-for="(tenantUser, i) in tenantUsers"
                            :key="i"
                            cols="12"
                            md="6"
                        >
                            <v-item>
                                <v-card
                                    class="mx-auto"
                                    outlined
                                >
                                    <v-list-item three-line>
                                    <v-list-item-content>
                                        <div class="text-overline mb-4">
                                            {{tenantUser.companyName}}
                                        </div>
                                        <v-list-item-title class="text-h5 mb-1">
                                            {{tenantUser.firstName}} {{tenantUser.lastName}} 
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{tenantUser.email}}</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-avatar size="40px">
                                        <img src="images/default_avatar.png"/>
                                    </v-avatar>
                                    </v-list-item>

                                    <v-card-actions>
                                    <v-btn
                                        outlined
                                        rounded
                                        text
                                        color="error"
                                        :disabled="tenantUser.id === user.id"
                                        @click="showDeleteUser(tenantUser)"
                                        :loading="loadingDelete"
                                    >
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                        delete user
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-item>
                        </v-col>
                        </v-row>
                    </v-item-group>
                </v-container>
            </v-card-text>
        </Dialog>
    </div>
</template>

<script>
import DeletionDialog from '../DeletionDialog.vue'
import Dialog from '../Dialog.vue'
import TenantAddUserDialog from '../tenants/TenantAddUserDialog.vue'

export default {
    components: { 
      Dialog,
      DeletionDialog,
      TenantAddUserDialog
    },

    props: [
        "tenant",
        "user"
    ],

    computed : {
    },

    methods: {
        doShow() {            
            this.getTenantUsers()
            this.show = true
        },

        async showAddUserTenant() {
            this.$refs.tenantAddUserDialog.doShow()
        },

        async showDeleteUser(user) {
            this.userDelete = user
            this.showDelete = true
        },

        async deleteUser(user) {
            this.loadingDelete = true
            //await this.$http.delete(`/users/${user.id}/tenants/rel/${this.tenant.id}`)
            await this.$http.delete(`/tenants/${this.tenant.id}/users/rel/${user.id}`)
            await this.getTenantUsers()
            this.loadingDelete = false
            this.showDelete = false
        },

        async getTenantUsers() {
            const {data} = await this.$http.get(`/tenants/${this.tenant.id}/users/`)
            this.tenantUsers = data

        }
    },

    data: () => {
        return {
            loading: false,
            loadingDelete: false,
            show: false,
            showDelete: false,
            tenantUsers: [],
            userDelete: {}
        }
    }
}
</script>

<style>

</style>