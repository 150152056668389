import { render, staticRenderFns } from "./DatabaseJobsOverview.vue?vue&type=template&id=8a84e360&scoped=true&"
import script from "./DatabaseJobsOverview.vue?vue&type=script&lang=js&"
export * from "./DatabaseJobsOverview.vue?vue&type=script&lang=js&"
import style0 from "./DatabaseJobsOverview.vue?vue&type=style&index=0&id=8a84e360&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a84e360",
  null
  
)

export default component.exports