import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { cspNonce: 'dQw4w9WgYcQ' },
        themes: {
          light: {
            primary: '#0036CE',
            secondary: '#FFA300',
            accent: colors.grey.base,
            error: '#ff5252',
            info: '#2196F3',
            success: '#4caf82',
            warning: '#FFC107'
          },
          dark: {
              primary: colors.grey.darken4,
              anchor: '#ffffff',
          }
        },
      },
});
