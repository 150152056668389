<!-- eslint-disable -->
<template>
    <div>
        <DeletionDialog
            type="User"
            :name="`${user.firstName} ${user.lastName}`"
            :show="showDelete"
            @cancel="showDelete = false"
            @delete="deleteUser(user)"
        >
        </DeletionDialog>

        <ApiAccessTokenDialog
            :user="user"
            :show="showApiAccessToken"
            @cancel="showApiAccessToken = false"
        >
        </ApiAccessTokenDialog>
    
        <Dialog     
            title="Update User Profile"
            :show="show"
            @close="show = false"
        >
            <ValidationObserver ref="user" v-slot="{ invalid }">
                <v-card-text>
                    <v-row>
                        <v-col md="4">
                            <v-avatar size="150px">
                                <img src="images/default_avatar.png"/>
                            </v-avatar>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <v-text-field readonly append-icon="email" label="Email" :value="user.email" outlined></v-text-field>
                                    <ValidationProvider name="name" rules="required"  v-slot="{ errors, valid }">      
                                        <v-text-field append-icon="person" label="First Name" v-model="user.firstName" outlined :error-messages="errors" :success="valid"></v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider name="lastname" rules="required"  v-slot="{ errors, valid }">      
                                        <v-text-field append-icon="person" label="Last Name" v-model="user.lastName" outlined :error-messages="errors" :success="valid"></v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider name="companyName" rules="required"  v-slot="{ errors, valid }">      
                                        <v-text-field append-icon="mdi-domain" label="Company Name" v-model="user.companyName" outlined :error-messages="errors" :success="valid"></v-text-field>
                                    </ValidationProvider>
                                    <ValidationProvider name="phone" rules="required|phone"  v-slot="{ errors, valid }">      
                                        <v-text-field append-icon="mdi-phone" label="Phone" v-model="user.phone" outlined :error-messages="errors" :success="valid"></v-text-field>
                                    </ValidationProvider>   
                                    <ValidationProvider name="billingMail" rules="required|email"  v-slot="{ errors, valid }">      
                                        <v-text-field append-icon="mdi-email" label="Billing Email*" v-model="user.billingMail" outlined :error-messages="errors" :success="valid"></v-text-field>
                                    </ValidationProvider> 
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn color="primary" :loading="loadingPasswordReset" outlined rounded @click="passwordReset(user)">
                                        <v-icon left>mdi-key</v-icon>
                                        Request Password Reset
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn color="primary" outlined rounded @click="doShowApiAccessToken()">
                                        <v-icon left>mdi-cloud-key</v-icon>
                                        Manage API Access Token
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="!user.twoFactorSecret">
                                <v-col>
                                    <v-btn color="primary" :loading="loadingTwoFactorReset" outlined rounded @click="twoFactorReset(user)">
                                        <v-icon left>mdi-two-factor-authentication</v-icon>
                                        Setup 2FA
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="user.twoFactorSecret">
                                <v-col>
                                    <v-btn color="primary" :loading="loadingTwoFactorReset" outlined rounded @click="twoFactorReset(user)">
                                        <v-icon left>mdi-two-factor-authentication</v-icon>
                                        Request 2FA Reset
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn color="error" :loading="loadingTwoFactorDisable" outlined rounded @click="twoFactorResetDisable(user)">
                                        <v-icon left>mdi-two-factor-authentication</v-icon>
                                        Disable 2FA
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn color="error" outlined rounded @click="showDeleteUser(user)">
                                        <v-icon left>mdi-delete</v-icon>
                                        Delete user
                                    </v-btn>
                                </v-col>
                            </v-row>                 
                        </v-col>                        
                    </v-row>
                </v-card-text>
               
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="patchUser(user)" :loading="loading" :disabled="invalid">Save</v-btn>
                </v-card-actions> 
            </ValidationObserver>  
        </Dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import DeletionDialog from '../DeletionDialog.vue'
import ApiAccessTokenDialog from './UserApiAccessTokenDialog.vue'
import Dialog from '../Dialog.vue'

export default {
    components: { 
        Dialog,
        DeletionDialog,
        ApiAccessTokenDialog
    },

    props: [
        "user"
    ],

    async created() {
        await this.$recaptchaLoaded()
    },

    methods: {
        ...mapMutations(['unsetAccessToken']),

        doShow() {
            this.show = true
        },

        async doShowApiAccessToken() {
            this.showApiAccessToken = true
        },

        async showDeleteUser(user) {
            this.user = user
            this.showDelete = true
        },

        async deleteUser(user) {
            this.loading = true
            const userId = user.id
            await this.$http.delete(`/users/${userId}`)
            this.loading = false
            this.unsetAccessToken()
        },

        async patchUser(user) {
            this.loading = true
            const userId = user.id
            const patch = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                companyName: user.companyName,
                phone: user.phone,
                billingMail: user.billingMail
            }
            await this.$http.patch(`/users/${userId}`, patch)
            this.loading = false
            this.show = false
            this.$emit('save')
        },

        async twoFactorResetDisable(user) {
            this.loadingTwoFactorDisable = true
            await this.$http.put(`/user/${user.id}/twoFactorDisable`)
            this.loadingTwoFactorDisable = false
            window.snackbar.queue.push({
                color: 'success',
                text: "Succesfully disabled 2FA",
            })      
            this.$emit('save')
        },

        async passwordReset(user) {
            this.loadingPasswordReset = true
            const passwordResetForm = {
                email: user.email
            }
            try {
                await this.$http.post('/user/passwordReset', passwordResetForm, {
                    headers : {
                    recaptcha : `Token ${await this.$recaptcha('passwordReset')}`
                    }
                });
                window.snackbar.queue.push({
                    color: 'success',
                    text: "Request send by email",
                })
            } catch(error) {console.log(error);}

            this.loadingPasswordReset = false
        },

        async generateApiAccessToken(user) {
            this.loadingPasswordReset = true
            const passwordResetForm = {
                email: user.email
            }
            try {
                await this.$http.post('/user/passwordReset', passwordResetForm, {
                    headers : {
                    recaptcha : `Token ${await this.$recaptcha('passwordReset')}`
                    }
                });
                window.snackbar.queue.push({
                    color: 'success',
                    text: "Request send by email",
                })
            } catch(error) {console.log(error);}

            this.loadingPasswordReset = false
        },

        async twoFactorReset(user) {
            this.loadingTwoFactorReset= true
            const twoFactorAuthResetForm = {
                email: user.email
            }
            try{
                await this.$http.post('/user/twoFactorReset', twoFactorAuthResetForm, {
                headers : {
                recaptcha : `Token ${await this.$recaptcha('twoFactorReset')}`
                    }
                });
                window.snackbar.queue.push({
                    color: 'success',
                    text: "Request send by email",
                })            
            } catch(error) {console.log(error);}
            this.loadingTwoFactorReset = false

        }
    },

    data: () => {
        return {
            loadingTwoFactorReset : false,
            loadingTwoFactorDisable : false,
            loadingPasswordReset: false,
            loading: false,
            show: false,
            showDelete: false,
            showApiAccessToken: false
        }
    }
}
</script>

<style>

</style>