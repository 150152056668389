import axios from 'axios'
import axiosRetry from 'axios-retry'

import store from '@/store'


axiosRetry(axios, { retries: 3,  
    retryCondition: (error) => {
        console.log({error});

        // add network error
        if(error.response == undefined) {
            window.snackbar.queue.push({
                color: 'info',
                text: "Network error, please check internet connection!",
            })
            return true
        }

        // add wrong login
        if(error.request.responseURL.includes('auth/login') && error.response.status >= 400 && error.response.status <= 499) {
            console.log(error);
            const message = error.response.data.error.message ? error.response.data.error.message : 'Unkown Error'
            window.snackbar.queue.push({
                color: 'warning',
                text: message,
            })
            return false
        }

        // add expired login
        if(error.response.status === 401) {
            store.commit('unsetAccessToken')
            return false
        }
    
        // add unprocessable request
        if(error.response.status === 422) {
            console.log(error);
            window.snackbar.queue.push({
                color: 'error',
                text: error.response.data.error.message,
            })
            return false
        }

        if(error.response.status === 500) {
            window.snackbar.queue.push({
                color: 'error',
                text: "Internal server error",
            })
            return true
        }
        return false
    },    
    retryDelay: (retryCount) => retryCount * 5 * 1000
});

axios.defaults.timeout = 30 * 1000;
axios.defaults.headers.common['Content-Type'] ='application/json';
axios.defaults.headers.common['Accept'] ='application/json';

// console.log(axios.defaults.baseURL)

axios.interceptors.request.use((config) => {
    if(typeof config.data === 'object') {
        const computedKeys = Object.keys(config.data).filter(i => i.includes("__"))
        for(const key of computedKeys) {
          delete config.data[key]
        }
    }
    return config
})
export default axios