<!-- eslint-disable -->
<template>
  <v-layout row justify-center>
    <v-dialog v-model="show" persistent max-width="500px">
      <v-card class="warning">
        <v-card-title >
          <v-icon large left>warning</v-icon>
          <span class="title font-weight-light">Confirm Deletion</span>
        </v-card-title>
        <v-card-text>Are you sure to delete {{ type }}: {{ name }}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="accent"  @click="$emit('cancel')">Cancel</v-btn>
          <v-btn color="accent" @click="$emit('delete')">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
