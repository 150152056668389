<!-- eslint-disable -->
<template>
    <div>
        <Dialog     
            :title="`Usages: ${connector.name}`"
            :show="show"
            fullscreen
            @close="show = false"
        >
            <v-card flat>
                <v-row>
                    <v-col> 
                        <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon x-large>
                                        mdi-chart-line
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h4 mb-1">
                                        {{ formatSizeString(usagesTotalsToday.sizeBytes) }}
                                    </v-list-item-title>
                                </v-list-item-content>              
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>                
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text">
                                        {{usagesTotalsToday.requestCount}} requests count
                                    </v-list-item-title>
                                    <v-list-item-title class="text">
                                        {{usagesTotalsToday.responseCount}} responses count
                                    </v-list-item-title>
                                </v-list-item-content>              
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Today's Usage</v-list-item-subtitle>
                                </v-list-item-content>              
                            </v-list-item>
        
                        </v-card>
                    </v-col>
                    <v-col> 
                        <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon x-large>
                                        mdi-chart-line
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h4 mb-1">
                                        {{ formatSizeString(usagesTotals.sizeBytes) }}
                                    </v-list-item-title>
                                </v-list-item-content>              
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar>                
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text">
                                        {{usagesTotals.requestCount}} requests count
                                    </v-list-item-title>
                                    <v-list-item-title class="text">
                                        {{usagesTotals.responseCount}} responses count
                                    </v-list-item-title>
                                </v-list-item-content>              
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>Total Usage</v-list-item-subtitle>
                                </v-list-item-content>              
                            </v-list-item>
        
                        </v-card>
                    </v-col>
                </v-row> 

                <v-row>
                    <v-col> 
                        <usage-per-day-line
                            :usagesTotalsPerDay="usagesTotalsPerDay"
                        ></usage-per-day-line>
                    </v-col>
                    <v-col> 
                        <usage-per-path-bar 
                            :usagesTotalsPerPath="usagesTotalsPerPath"
                            >
                        </usage-per-path-bar>
                    </v-col>
                </v-row>
                 <v-card-title>        
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        outlined
                        dense
                        hide-details
                    ></v-text-field>

                    <v-btn @click="getUsages()" color="secondary" class="mx-2">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-switch
                        v-model="autoRefreshLogs"
                        :label="`Auto Refresh (5 sec.)`"
                    ></v-switch>
                </v-card-title>
                <v-row>
                    <v-col>        
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="usages"
                            :search="search"
                            show-expand
                            :expanded.sync="expanded"
                            single-expand
                            item-key="id"
                            sort-by='id'
                            sort-desc
                            multi-sort
                        >
                
                            <template v-slot:item.created="{ item }">
                                {{ new Date(item.created).toLocaleString() }}
                            </template>

                            <template v-slot:item.paremeterString="{ item }">
                                {{ $utils.overflow(item.paremeterString, 100) }}
                            </template>

                            <template v-slot:item.sizeBytes="{ item }">
                                {{ formatSizeString(item.sizeBytes) }}
                            </template>

                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" flat>
                                    <v-card class="mx-2" outlined>
                                        <v-card-title>
                                        Parameters
                                        </v-card-title>
                                        <v-simple-table flat>
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th class="text-left">
                                                    Parameter
                                                </th>
                                                <th class="text-left">
                                                    Value
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="(value, key) in item.parameters"
                                                :key="key"
                                                >
                                                <td>{{ key }}</td>
                                                <td>{{ value }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </td>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </Dialog>
    </div>
</template>

<script>
import UsagePerDayLine from '../charts/usagePerDayLine.vue'
import UsagePerPathBar from '../charts/usagePerPathBar.vue'
import Dialog from '../Dialog.vue'

const formatSizeString = (sizeBytes) => {
    const decimals = 2
    if (!+sizeBytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(sizeBytes) / Math.log(k))
    return `${parseFloat((sizeBytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export default {
    components: { 
        Dialog,
        UsagePerPathBar,
        UsagePerDayLine
    },

    props: [
        "connector"
    ],

    methods: {
        doShow() {
            this.show = true
            this.getUsages()
            this.doAutoRefreshUsages()
        },

        formatSizeString: formatSizeString,

        async getUsages() {
            this.loading=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connectors/${connectorId}/usages`)
            console.log(data)

            this.usages = data.map(i => {
                return {...i, ...{
                paremeterString: JSON.stringify(i.parameters).replace('{', '').replace('}', '').replace(/:/g, "=").replace(/"/g,''),
                sizeBytes: i.responseSizeBytes,
                responseCount: i.responseCount
            }}})

            this.usagesTotals = this.usages.reduce((usages, usage) => {   
                usages = {
                    requestCount: usages['requestCount'] ? usages['requestCount'] + 1 :  1,
                    responseCount: usages['responseCount'] ? usages['responseCount'] + usage.responseCount : usage.responseCount,
                    sizeBytes: usages['sizeBytes']  ? usages['sizeBytes'] + usage.sizeBytes : usage.sizeBytes
                }      
                return usages
             },{})

             this.usagesTotalsPerPath = this.usages.reduce((usages, usage) => {
                const path = usage.path
                usages[path] = {
                    requestCount: usages[path] ? usages[path]['requestCount'] + 1 :  1,
                    responseCount: usages[path] ? usages[path]['responseCount'] + usage.responseCount : usage.responseCount,
                    sizeBytes: usages[path] ? usages[path]['sizeBytes'] + usage.sizeBytes : usage.sizeBytes
                }      
                return usages
            },{})

            this.usagesTotalsPerDay = this.usages.reduce((usages, usage) => {
                const dateOnly = new Date(new Date(usage.created).toDateString())
                usages[dateOnly] = {
                    requestCount: usages[dateOnly] ? usages[dateOnly]['requestCount'] + 1 :  1,
                    responseCount: usages[dateOnly] ? usages[dateOnly]['responseCount'] + usage.responseCount : usage.responseCount,
                    sizeBytes: usages[dateOnly] ? usages[dateOnly]['sizeBytes'] + usage.sizeBytes : usage.sizeBytes
                }      
                return usages
            },{})


            this.usagesTotalsToday = this.usagesTotalsPerDay[new Date(new Date().toDateString())] ? this.usagesTotalsPerDay[new Date(new Date().toDateString())]  : {requestCount: 0, responseCount: 0, sizeBytes: 0,}
           

            this.loading=false
        },

        async doAutoRefreshUsages() {
            // do 10 minutes
            let count = 0
            do  {               
                if(this.autoRefreshLogs) {
                     console.log('refreshed usages!');
                    this.getUsages()
                    count ++
                }                
                await new Promise(resolve => setTimeout(resolve, 5000));                
            } while (count < 120 && this.show)
        }
    },

    data: () => {
        return {
            loading: false,
            show: false,
            showDropSpeed: false,
            showDropPersistent: false,
            autoRefreshLogs: false,
            expanded: [],
            usagesTotals: {},
            usagesTotalsToday: {},
            usagesTotalsPerDay: {},
            usagesTotalsPerPath: {},
            usages: [],
            search: "",
            headers: [
                { text: 'Date', value: 'created' },
                { text: 'Path', value: 'path' },
                { text: 'Parameters', value: 'paremeterString' },
                { text: 'Size', value: 'sizeBytes' },
                { text: 'Response Count', value: 'responseCount' }
            ]
        }
    }
}
</script>

<style scoped>
    .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    }
</style>