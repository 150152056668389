<!-- eslint-disable -->
<template>
    <div>
        <DeletionDialog
            type="Incremental Data"
            :name="connector.name"
            :show="showDropIncrementalStorage"
            @cancel="showDropIncrementalStorage = false"
            @delete="dropIncrementalStorage(connector)"
        >
        </DeletionDialog>

        <DeletionDialog
            type="Incremental Data"
            :name="$utils.overflow(selectedEntity._id.syncKey, 100)"
            :show="showDeleteIncrementalEntityStorage"
            @cancel="showDeleteIncrementalEntityStorage = false"
            @delete="deleteIncrementalEntityStorage(selectedEntity)"
        >
        </DeletionDialog>


        <Dialog     
            :title="`Incremental Data: ${connector.name}`"
            :show="show"
            fullscreen
            @close="show = false"
        >

            <v-card flat>
                <v-row>
                    <v-col> 
                        <v-card
                            class="mx-auto"
                            outlined
                        >
                            <v-list-item two-line>
                                <v-list-item-avatar>

                                    <v-progress-circular color="primary" v-if="loadingStorageUsage" indeterminate model-value="20">
                                    </v-progress-circular>
                                        <v-icon v-else x-large>
                                            mdi-database-clock
                                        </v-icon>
                                        
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-h4 mb-1">
                                        {{ TotalIncremental }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Incremental Data</v-list-item-subtitle>
                                </v-list-item-content>              
                            </v-list-item>
            
                            <v-card-actions>
                                <v-spacer />
                                <v-btn @click="showDropIncrementalStorage = true" color="secondary" small rounded>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn> 
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <v-card-title>        
                    <v-spacer></v-spacer>
                    <v-btn @click="refresh()" color="secondary" class="mx-2">
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <v-switch
                        v-model="autoRefreshLogs"
                        :label="`Auto Refresh (15 sec.)`"
                    ></v-switch>
                </v-card-title>
                <v-card-title>Sync entities with trigger</v-card-title>
                <v-row>
                    <v-col>
                        <v-data-table
                            :loading="loadingEntities"
                            :headers="headersEntities"
                            :items="entities"
                            item-key="syncKey"
                            sort-by='key'
                            sort-desc
                            multi-sort
                        >
                        <template v-slot:item.trigger="{ item }">
                        <v-layout justify-end>
                            <v-btn
                                @click="triggerEntitySync(item)"
                                color="secondary"
                                dark
                                :loading="loadingTrigger"
                                small 
                                rounded
                            >
                                <v-icon>mdi-cloud</v-icon>
                            </v-btn>
                        </v-layout>
                    </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-card-title>Incremental storage (used shard key : {{ shardKey }})</v-card-title>
                <v-row>
                    <v-col>        
                        <v-data-table
                            :loading="loadingIncrementalStorage"
                            :headers="headersStorage"
                            :items="storage"
                            item-key="syncKey"
                            sort-by='count'
                            sort-desc
                            multi-sort
                        >

                        <template v-slot:item.syncKey="{ item }">
                            {{ $utils.overflow(item._id.syncKeySub ? item._id.syncKeySub : item._id.syncKey, 100) }}
                        </template>


                        <template v-slot:item.delete="{ item }">
                        <v-layout justify-end>
                            <v-chip
                                @click="doShowDeleteIncrementalEntityStorage(item)"
                                color="secondary"
                                dark
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-chip>
                        </v-layout>
                    </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </Dialog>
    </div>
</template>

<script>
import DeletionDialog from '../DeletionDialog.vue'
import Dialog from '../Dialog.vue'

const formatSizeString = (sizeBytes) => {
    const decimals = 2
    if (!+sizeBytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(sizeBytes) / Math.log(k))
    return `${parseFloat((sizeBytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

//TODO: FIX QUERY WITH SUBSYNCKEY
export default {
    components: { 
        Dialog,
        DeletionDialog
    },

    props: [
        "connector"
    ],

    methods: {
        doShow() {
            this.show = true
            this.fetchData()
            this.doAutoRefreshstorageUsage()
        },

        async fetchData() {
            this.getShardKey()
            this.getIncrementalUsage()
            this.getSyncEntityNames()
            this.getIncremental()
        },

        async getIncremental() {
            this.loadingIncrementalStorage=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connector/${connectorId}/incremental`)
            this.storage = data
            this.loadingIncrementalStorage = false
        },

        async getIncrementalUsage() {
            this.loadingStorageUsage=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connector/${connectorId}/incremental/usage`)
            this.storageUsage = data.map(i => {return {...i, ...{
                sizeString: formatSizeString(i.sizeBytes),
            }}})
            this.TotalIncremental = formatSizeString(
                this.storageUsage
                    .filter(i => i.type === 'Incremental')
                    .reduce((t, i) => t + i.sizeBytes, 0)
            )
            this.loadingStorageUsage=false
        },

        async getSyncEntityNames() {
            this.loadingEntities=true
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connector/${connectorId}/incremental/sync-entity-names`)
            this.entities = data
            this.loadingEntities = false
        },

        async getShardKey() {
            const connectorId = this.connector.id
            const {data} = await this.$http.get(`/connector/${connectorId}/incremental/shard-key`)
            this.shardKey = data
        },

        async triggerEntitySync(item) {
            this.loadingTrigger=true
            const connectorId = this.connector.id
            await this.$http.post(`/connector/${connectorId}/incremental/${item.entity}/sync-data`)
            window.snackbar.queue.push({
                color: 'success',
                text: "Succesfully triggerd Incremental sync",
            })
            this.loadingTrigger = false
        },

        async doShowDeleteIncrementalEntityStorage(item) {
            this.selectedEntity = item
            this.showDeleteIncrementalEntityStorage = true
        },        

        async deleteIncrementalEntityStorage(item) {
            const connectorId = this.connector.id
            await this.$http.delete(`/connector/${connectorId}/incremental/${item._id.syncKey}`)
            window.snackbar.queue.push({
                color: 'success',
                text: "Succesfully triggerd Incremental delete",
            })
            this.refresh()
            this.showDeleteIncrementalEntityStorage = false
        },
        
        async dropIncrementalStorage() {
            const connectorId = this.connector.id
            await this.$http.delete(`/connector/${connectorId}/incremental`)
            this.refresh()
            this.showDropIncrementalStorage = false
        },

        async refresh() {
             this.getIncrementalUsage()
             this.getIncremental()
        },


        async doAutoRefreshstorageUsage() {
            // do 10 minutes
            let count = 0
            do  {               
                if(this.autoRefreshLogs) {
                    this.getIncrementalUsage()
                    this.getIncremental()
                    count ++
                }                
                await new Promise(resolve => setTimeout(resolve, 15000));                
            } while (count < 60 && this.show)
        }
    },

    data: () => {
        return {
            loadingStorageUsage: false,
            loadingIncrementalStorage: false,
            loadingEntities: false,
            loadingTrigger: false,
            show: false,
            showDropIncrementalStorage: false,
            showDeleteIncrementalEntityStorage: false,
            autoRefreshLogs: false,
            shardKey: "",
            TotalIncremental: 0,
            selectedEntity: {
                _id: {
                    syncKey: '...'
                }
            },
            storageUsage: [],
            expanded: [],
            entities: [
            ],
            storage: [
            ],
            headersEntities: [
            { text: 'Entity', value: 'entity'},
            { text: 'Trigger', value: 'trigger', width: '100px' }],
            headersStorage: [
            { text: 'syncKey', value: 'syncKey', width: '300px' },
            { text: 'Size in rows', value: 'count' },
            { text: 'Delete', value: 'delete', width: '100px' }]
        }
    }
}
</script>

<style scoped>
    .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    box-shadow: none;
    }
</style>