<!-- eslint-disable -->
<template>
  <div>
    <v-card-title> Jobs</v-card-title>
    <v-tabs
      v-model="activeTab"
      align-tabs="center"
    >
      <v-tab v-for="(tab, index) in tabs" :key="index" >{{tab}}</v-tab>
    </v-tabs>

    <v-tabs-window v-model="activeTab">
      <request-jobs-overview v-if="activeTab === 0" :user="user"></request-jobs-overview>
      <database-jobs-overview v-if="activeTab === 1" :user="user"></database-jobs-overview>
    </v-tabs-window>
  </div>
</template>

<script>
import RequestJobsOverview from '../components/jobs/RequestJobsOverview.vue';
import DatabaseJobsOverview from '../components/jobs/DatabaseJobsOverview.vue';

export default {
  components: { RequestJobsOverview, DatabaseJobsOverview },
  props: ["user"],

  data: () => {
      return {
        tabs : [
          'Request',
          'Database'
        ],
        activeTab: 0
      }
  }
}
</script>

<style scoped>
</style>