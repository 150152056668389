  <!-- eslint-disable -->
  <template>
    <div class="Tenants">
      <!-- add / update /delete -->
      <tenant-dialog 
        :key="`${tenant.id}_${$utils.uuid()}`"
        @save="$emit('refreshTenants')" 
        :tenant="tenant" 
        :tenants="tenants"
        :user="user"
        ref="tenantDialog"
      ></tenant-dialog>
  
      <!-- Info -->
      <tenant-info-dialog 
        :key="`${tenant.id}_${$utils.uuid()}`"
        :tenant="tenant"
        ref="infoTenantDialog"
      ></tenant-info-dialog>
  
      <!-- add / update /delete users to tenant -->
      <tenant-user-dialog 
        :key="`${tenant.id}_${$utils.uuid()}`"
        @save="$emit('refreshTenants')" 
        :tenant="tenant"
        :user="user"
        ref="tenantUserDialog"
      ></tenant-user-dialog>
  
  
      <!-- Page -->
      <v-card flat>
        <v-card-title>
          Tenants
        </v-card-title>
        <v-card-title>        
          <v-btn @click="openTenantDialog()" color="primary" class="mx-2">
            <v-icon>add</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="tenants"
          :search="search"
          sort-by='name'
          multi-sort
        >
          <template v-slot:item.name="{ item }">
            {{ $utils.overflow(item.name, 35) }}
            <v-btn
              @click="openInfoDialog(item)"
              icon
              x-small
            >
              <v-icon> mdi-link-variant </v-icon>
            </v-btn>
          </template>
  
          <template v-slot:item.description="{ item }">
            {{ $utils.overflow(item.description, 35)}}          
          </template>
  
          <template v-slot:item.edit="{ item }">
            <v-chip
              @click="openTenantDialog(item)"
              color="secondary"
              dark
            >
              <v-icon>mdi-circle-edit-outline</v-icon>
            </v-chip>
          </template>
  
          <template v-slot:item.users="{ item }">
            <v-chip
              @click="openTenantUserDialog(item)"
              color="secondary"
              dark
            >
              <v-icon>mdi-account-supervisor-circle</v-icon>
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </template>
  
  <script>
  import TenantDialog from '../components/tenants/TenantDialog.vue'
  import TenantUserDialog from '../components/tenants/TenantUserDialog.vue'
  import TenantInfoDialog from '../components/tenants/TenantInfoDialog.vue'
  
  export default {  
    name: 'Tenants',
  
    props: [
      "user",
      "tenants",
    ],
  
    components: { TenantDialog, TenantUserDialog, TenantInfoDialog },
    async mounted() {
  
    },
  
    methods: {
  
      openTenantDialog(tenant = false) {
        this.tenant = tenant ? JSON.parse(JSON.stringify(tenant)) : { 
          name : "",
          description : "",
        }
        this.$nextTick(() => {
          this.$refs.tenantDialog.doShow();
        })
      },
  
      openInfoDialog(tenant = false) {
        console.log({tenant})
        this.tenant = JSON.parse(JSON.stringify(tenant))
        console.log({tenant})
        this.$nextTick(() => {
          this.$refs.infoTenantDialog.doShow();
        })
      },
  
      openTenantUserDialog(tenant = false) {
        this.tenant = JSON.parse(JSON.stringify(tenant)) 
        this.$nextTick(() => {
          this.$refs.tenantUserDialog.doShow();
        })
      }
  
    },
  
    data: () => {
      return {
        tenant: false,
        search: "",
        headers: [
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Edit', value: 'edit' },
            { text: 'Users', value: 'users' }
        ]
      }
    }
  }
  </script>