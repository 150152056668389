import Vue from 'vue'
import Vuex from 'vuex'

import axios from '@/plugins/axios'
import createPersistedState from "vuex-persistedstate";

import SecureLS from "secure-ls";
const secureLs = new SecureLS({ isCompression: false });

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'


export default new Vuex.Store({
  state: {
    accessToken: null,
    apiServers: [
        "https://dwh.cleversight.one/api",
        "https://dwh-acceptance.cleversight.one/api",
        "http://localhost:3001/api",
    ],
    apiServer: process.env.NODE_ENV !== "production" ? "https://dwh-acceptance.cleversight.one/api" : `${window.location.origin}/api`
  },
  mutations: {
    setAccessToken: (state, data) => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${data}`;
      state.accessToken = data
    },
    unsetAccessToken: (state) => {
      delete axios.defaults.headers.common['Authorization'];
      state.accessToken = null
    },
    setApiServer: (state, data) => {
      axios.defaults.baseURL = data;
      state.apiServer = data
    },
  },
  strict: debug,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => secureLs.get(key),
        setItem: (key, value) => secureLs.set(key, value),
        removeItem: (key) => secureLs.remove(key),
      },
    })
  ],
})
