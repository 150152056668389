<!-- eslint-disable -->
<template>
  <div>
      <Toolbar :user="user" @save="getUser()"/>
      <Drawer/>
      <v-main>
        <v-container id="container" fluid>
          <transition name="fade" mode="out-in">
            <router-view
              v-if="!loading"
              :key="$route.fullPath"
              :user="user" 
              :tenants="tenants"
              @refreshTenants="getTenants()"
            />
          </transition>
        </v-container>
      </v-main>
      <Footer/>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Drawer from './components/Drawer.vue'
import Footer from './components/Footer.vue'
import Toolbar from './components/Toolbar.vue'

export default {
  components: {
    Footer,
    Toolbar,
    Drawer
  },

  async mounted() {
    this.loading = true

    // logoff if payload url is given
    if(this.$route.query.payload) {
      const payload = JSON.parse(atob(this.$route.query.payload))
      if(payload.type === 'sign-up-complete' 
        || payload.type === 'password-reset-complete' 
        || payload.type === 'two-factor-reset-complete'
        || payload.type === 'verify-ip-address'
      ) {
        await this.unsetAccessToken()
        return
      }
    }

    await this.getUser()
    await this.getTenants()
    this.loading = false  
  },

  methods: {
    ...mapMutations(['unsetAccessToken']),

    async setLoading(loading) {
      this.loading = loading
    },
    async getUser() {
      this.loading = true
      const {data} = await this.$http.get(`/auth/whoAmI`)
      this.user = data
      this.loading = false
    },

    async getTenants() {
      const userId = this.user.id
      if(userId) {
        const {data} = await this.$http.get(`/users/${userId}/tenants`)
        this.tenants = data 
      }      
    }
  },

  data: () => {
    return {
      loading: false,
      user : {},
      tenants : []
    }
  }
}
</script>

<style scoped>
  #container {
    padding-top: 64px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
