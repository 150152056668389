<!-- eslint-disable -->
<template>
  <!-- TODO: make tab for Jobs database queue-->
  <div class="Jobs Dashboard">
    <v-row>
      <v-col>
        <v-card-subtitle > Shows the database jobs. </v-card-subtitle>           

      </v-col>
      <v-col cols="2">
        <v-switch
            v-model="autoRefreshRequests"
            :label="`Auto Refresh (5 sec.)`"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
        <v-col> 
            <v-card
                class="mx-auto"
                outlined
            >
                <v-list-item two-line>
                    <v-list-item-avatar>
                        <v-icon x-large>
                            mdi-database-clock
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-h4 mb-1">
                            {{ activeJobs }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Active Jobs</v-list-item-subtitle>
                    </v-list-item-content>              
                </v-list-item>
            </v-card>
        </v-col>

      </v-row>

    <v-card flat>

        <v-card-title>        
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                outlined
                dense
                hide-details
            ></v-text-field>
            <v-btn @click="getJobs()" color="secondary" class="mx-2">
                <v-icon>refresh</v-icon>
            </v-btn>

        </v-card-title>
        <v-row>
            <v-col>        
                <v-data-table
                    :loading="loading"
                    :headers="headersJob"
                    :search="search"
                    show-expand
                    :expanded.sync="expanded"
                    single-expand
                    :items="jobs"
                    item-key="id"
                    sort-by='processedOn'
                    sort-desc
                    multi-sort
                    :items-per-page="25"                         
                >

                <template v-slot:body.prepend>
                  <tr>
                    <td></td>
                    <td>
                      <v-autocomplete
                        v-if="tenantNames"
                        v-model="tenantNameFilter"
                        :items="tenantNames"
                        no-data-text="No data!"
                        clearable
                      > 
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        v-if="connectorNames"
                        v-model="connectorNameFilter"
                        :items="connectorNames"
                        no-data-text="No data!"
                        clearable
                      > 
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        v-if="jobIds"
                        v-model="jobIdsFilter"
                        :items="jobIds"
                        no-data-text="No data!"
                        clearable
                      > 
                      </v-autocomplete>
                    </td>
                    <td></td>
                    <td>
                      <v-autocomplete
                        v-if="cacheKeys"
                        v-model="cacheKeysFilter"
                        :items="cacheKeys"
                        no-data-text="No data!"
                        clearable
                      > 
                      </v-autocomplete>
                    </td>

                    <td>
                      <v-autocomplete
                        v-if="cacheTypes"
                        v-model="cacheTypesFilter"
                        :items="cacheTypes"
                        no-data-text="No data!"
                        clearable
                      > 
                      </v-autocomplete>
                    </td>
                    <td>
                      <v-autocomplete
                        v-if="jobStatuses"
                        v-model="jobStatusesFilter"
                        :items="jobStatuses"
                        no-data-text="No data!"
                        clearable
                      > 
                      </v-autocomplete>
                    </td>

                  </tr>
                </template>
        
                  <template v-slot:item.data.cacheOptions.cacheKey="{ item }">
                        {{ $utils.overflow(item.data.cacheOptions.cacheKey, 100) }}
                    </template>
                    <template v-slot:item.processedOn="{ item }">
                        {{ new Date(item.processedOn).toLocaleString() }}
                    </template>
                    <template v-slot:item.finishedOn="{ item }">
                        {{ new Date(item.finishedOn).toLocaleString() }}
                    </template>

                    <template v-slot:item.secondsRunning="{ item }">
                        {{  formatTtlString(item.secondsRunning) }}
                    </template>

                    <template v-slot:item.retry="{ item }">
                      <v-btn @click="duplicateJob(item.__connector.name, item.id)" color="secondary" class="mx-2" chip>
                        <v-icon>mdi-content-copy</v-icon>
                    </v-btn>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-card outlined>
                  
                                  <v-card-title>
                                  Data
                                  </v-card-title>
                                  <v-card-text>
                                    <v-textarea                         
                                        outlined
                                        readonly
                                        :value="JSON.stringify(item.data, null, 2)"
                                        label= "data"
                                        auto-grow
                                        auto-focus
                                    />
                                  </v-card-text>

                                  <v-card-title>
                                  Fail Reason
                                  </v-card-title>
                                  <v-card-text>
                                      {{ item.failedReason }}
                                  </v-card-text>

                                  <v-card-title>
                                  Returnvalue
                                  </v-card-title>
                                  <v-textarea                         
                                    outlined
                                    readonly
                                    :value="JSON.stringify(item.returnvalue, null, 2)"
                                    label= "Returnvalue"
                                    auto-grow
                                    auto-focus
                                />


                                  <v-card-title>
                                  StackTrace
                                  </v-card-title>
                                  <v-card-text>
                                      {{ item.stacktrace }}
                                  </v-card-text>
                              </v-card>
                            </v-col>
                        </v-row>
                      </v-container>
                    </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>
  </div>
</template>

<script>



export default {
  props: ["user"],

  beforeDestroy() {
    this.show = false
  },

  async mounted() {
      this.show = true
      await this.getConnectorIds()
      await this.getJobs();
      this.doAutoRefreshRequests();
  },

  methods: {
    
    formatTtlString (secondsIn) {
      var seconds = parseInt(secondsIn, 10);

      var hrs   = Math.floor(seconds / 3600);
      seconds  -= hrs*3600;
      var mnts = Math.floor(seconds / 60);
      seconds  -= mnts*60;
      return `${hrs} Hrs, ${mnts} Minutes, ${seconds} Sec,`
    },

    async duplicateJob(connectorId, jobId) {
      try {
        await this.$http.get(
          `connectors/${connectorId}/databaseQueue/${jobId}/duplicate`
        );
        window.snackbar.queue.push({
            color: "success",
            text: "Succesfully Duplicated the job!",
        });
      } catch (error) {console.log(error);}
      this.getJobs()
    },

    async getDatabaseQueue(connectorIds) {
      const { data } = await this.$http.post(
        `connectors/databaseQueue`, connectorIds
      );
      return data
    },

    async getConnectorIds() {
      this.loading = true
      const userId = this.user.id
      const filter = {
        include: ['connectors']
      }
      const {data} = await this.$http.get(`/users/${userId}/tenants?filter=${encodeURIComponent(JSON.stringify(filter))}`)
      const connectorIds = data.reduce((connectorIds, tenant) => {
        let connectorIdsOfTenant = [];
        if (tenant.connectors) {
          connectorIdsOfTenant = tenant.connectors.map(
            (connector) => connector.id,
          );
        }
        return [...connectorIds, ...connectorIdsOfTenant];
      }, [])
      this.tenants = data
      this.connectorIds = connectorIds
      this.loading = false
    },

    async getJobs() {
      this.loading = true
      this.jobs = []

      const jobs = await this.getDatabaseQueue(this.connectorIds)
      for(const tenant of this.tenants) {
        if(tenant.connectors) {
          for(const connector of tenant.connectors) {              
              const connectorJobs = jobs.filter(i=> i.data.connectorId === connector.id)

              for(const job of connectorJobs) {
                job.data.cacheOptions = job.data.cacheOptions ? job.data.cacheOptions : { cacheKey: "",  cacheType : ""}
                this.jobs.push({...job, __tenant : tenant, __connector : connector})
              }
          }
        }
      }
      this.loading = false
    },

    async doAutoRefreshRequests() {
      // do 10 minutes
      let count = 0;
      do {
        if (this.autoRefreshLogs) {
          console.log("refreshed logs!");
          this.getJobs();
          count++;
        }
        await new Promise((resolve) => setTimeout(resolve, 5000));
      } while (count < 120 && this.show);
    },

  },

  computed : {
    headersJob() {
      return [
        { text: "Tenant", value: "__tenant.name", filter: f => this['tenantNameFilter'] ? ( f + '' ).includes( this['tenantNameFilter']) : true},
        { text: "Connector", value: "__connector.name", filter: f => this['connectorNameFilter'] ? ( f + '' ).includes(this['connectorNameFilter'] ): true},
        { text: "Job Id", value: "id", filter: f => this['jobIdsFilter'] ? ( String(f) + '' ).includes( this['jobIdsFilter']) : true},
        { text: "Name", value: "data.name" },
        { text: "Cache Key", value: "data.cacheOptions.cacheKey", filter: f => this['cacheKeysFilter'] ? ( f + '' ).includes( this['cacheKeysFilter']) : true},
        { text: "Cache Type", value: "data.cacheOptions.cacheType", filter: f => this['cacheTypesFilter'] ? ( f + '' ).includes( this['cacheTypesFilter']) : true},
        { text: "Status", value: "status" , filter: f => this['jobStatusesFilter'] ? ( f + '' ).includes( this['jobStatusesFilter']) : true},
        { text: "Start", value: "processedOn" },
        { text: "End", value: "finishedOn" },
        { text: "Running", value: "secondsRunning" },
        { text: "Retry", value: "retry" },

        //{ text: 'Date', value: 'created' },
        //{ text: 'Path', value: 'path' }, 
        //{ text: 'Parameters', value: 'paremeterString' },
        //{ text: 'Url', value: 'url'},
      ]
    },
    
    connectorNames() {
      return [... new Set(this.jobs.map(i => String(i.__connector.name)))]
    },

    tenantNames() {
      return [... new Set(this.jobs.map(i => String(i.__tenant.name)))]
    },
    jobIds() {
      return [... new Set(this.jobs.map(i => String(i.id)))]
    },
    cacheKeys() {
      return [... new Set(this.jobs.map(i => String(i.data.cacheOptions.cacheKey)))]
    },
    cacheTypes() {
      return  [... new Set(this.jobs.map(i => String(i.data.cacheOptions.cacheType)))]
    },
    jobStatuses() {
      return  [... new Set(this.jobs.map(i => String(i.status)))]
    },

    activeJobs() {
      return this.jobs.filter(i => i.status === 'Active').length
    }
  },

  data: () => {
      return {
          loading: false,
          show: false,
          autoRefreshRequests: false,
          clients: {},
          caches: [],
          expanded: [],
          tenants: [],
          connectorIds : [],
          jobs: [],
          search: "",
          connectorNameFilter: null,
          tenantNameFilter: null,
          jobIdsFilter: null,
          cacheKeysFilter: null,
          cacheTypesFilter: null,
          jobStatusesFilter: null
      }
  }
}
</script>

<style scoped>
  .v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
  }
</style>