<template>
    <v-footer
      app  
      height="50px"
      padless
      fixed
    >
      <v-flex xs12 >
      <v-card
        flat
        tile
        class="text-center"
      >
        <v-card-text >
            Cleversight DWH | &copy; <a href="https://cleversight.one" > <strong>CLEVERSIGHT</strong></a>  {{ `2021 - ${new Date().getFullYear()}` }} | All Rights Reserved | <a @click="$vuetify.theme.dark = !$vuetify.theme.dark">Try Dark Mode!</a>
        </v-card-text>
      </v-card>
      </v-flex>
    </v-footer>
</template>

<script>
export default {

};
</script>
