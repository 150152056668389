<template>
 <div v-if="Object.keys(usagesTotalsPerPath).length > 0">
    <v-card
        class="mx-auto"
        outlined
    >
        <v-list-item-title class="text mx-2">
           Usage per Path
        </v-list-item-title>
        <bar-chart :chartData="chartData"></bar-chart>
    </v-card>
 </div>
</template>

<script>
import BarChart from './base/BarChart.vue';

const getSizeScale = (sizeBytes) => {
    if (!+sizeBytes) return {
        divider: 1,
        sizeLabel: 'Bytes'
    }
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(sizeBytes) / Math.log(k))
    return {
        divider: Math.pow(k, i),
        sizeLabel: sizes[i]
    }
}

export default {
    components: {
        BarChart
        
    },

    props: [
        "usagesTotalsPerPath"
    ],

    computed: {
        chartData() {
            const labels = [] //[ 'January', 'February', 'March' ]
            const dataSetSizeBytes = {
                label: '',
                barThickness: 20,
                maxBarThickness: 20,
                backgroundColor: "#FFA300",
                data: [] 
            }

            const dataSetResponseCount = {
                label: 'Response count',
                hidden: true,
                barThickness: 20,
                maxBarThickness: 20,
                backgroundColor: "#FFDA99",
                data: [] 
            }

            const dataSetRequestCount = {
                label: 'Request count',
                hidden: true,
                barThickness: 20,
                maxBarThickness: 20,
                backgroundColor: "#FFEDCC",
                data: [] 
            }

            const maxSize = Math.max(...Object.values(this.usagesTotalsPerPath).map(i => i.sizeBytes))
            const sizeScale = getSizeScale(maxSize)
            dataSetSizeBytes.label = `Size (${sizeScale.sizeLabel})`

            // Sort by SizeBytes
            const usagesTotalsPerPathSorted = Object.fromEntries(
                Object.entries(this.usagesTotalsPerPath).sort(([,a],[,b]) => b.sizeBytes - a.sizeBytes)
            )

            // create labels and add
            for(const [label, values] of Object.entries(usagesTotalsPerPathSorted)) {
                labels.push(label)
                dataSetSizeBytes.data.push( (values.sizeBytes / sizeScale.divider).toFixed(2))
                dataSetRequestCount.data.push(values.requestCount)
                dataSetResponseCount.data.push(values.responseCount)
               
            }
            return {
                labels,
                datasets: [
                    dataSetSizeBytes,
                    dataSetResponseCount,
                    dataSetRequestCount
                ]                
            }

        }
    },


    data() {
        return {
    
        }
    }

}
</script>

<style>
</style>