<template>
  <div id="pagelogin" class="primary">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex  :class='`xs12 sm6 md6 lg3`'>

            <v-card class="elevation-1 pa-6">
              <v-card-title>
                <v-layout row wrap>
                  <v-flex lg12 sm12 xs12>
                    <v-img class="mx-6" src="@/assets/logo-vertical.svg">
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-card-title>

                <!-- Login -->
                <template v-if="stage === 'login'">
                  <ValidationObserver ref="login" v-slot="{ invalid }">
                    <v-card-text>                 
                      <v-form >
                        <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">        
                          <v-text-field 
                            append-icon="mdi-email" 
                            label="Email" 
                            v-model="loginForm.email" 
                            outlined
                            :error-messages="errors"
                            :success="valid"
                          ></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="password" rules="required"  v-slot="{ errors, valid }">     
                          <v-text-field 
                            append-icon="lock"  
                            label="Password" 
                            id="password" 
                            type="password" 
                            v-model="loginForm.password" 
                            autocomplete="password login" 
                            outlined
                            :error-messages="errors"
                            :success="valid"
                            >
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                  
                    </v-card-text>
                    <v-card-actions>
                      <a @click="setCurrentStage('password-reset')">
                        Forget password?
                      </a>
                      <v-spacer></v-spacer>
                      <v-btn  @click="setCurrentStage('sign-up')">Sign Up</v-btn>
                      <v-btn color="primary" @click="postLogin()" :loading="loading" :disabled="invalid">Login</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <!-- Login Two Factor -->
                <template v-if="stage === 'twoFactorTokenValidation'">
                  <ValidationObserver ref="twoFactorTokenValidation" v-slot="{ invalid }">
                    <v-card-text>                 
                      <v-form >
                        <ValidationProvider name="2FA Token" rules="required"  v-slot="{ errors, valid }">     
                          <v-text-field 
                            append-icon="lock"  
                            label="2FA Token" 
                            id="twoFactorToken"
                            v-model="loginForm.twoFactorToken" 
                            autocomplete="2FA Token" 
                            outlined
                            :error-messages="errors"
                            :success="valid"
                            >
                          </v-text-field>
                        </ValidationProvider>
                      </v-form>
                  
                    </v-card-text>
                    <v-card-actions>
                      <a @click="setCurrentStage('two-factor-reset')">
                        Reset 2FA?
                      </a>
                      <v-spacer></v-spacer>
                      <v-btn  @click="setCurrentStage('login')">Back</v-btn>
                      <v-btn color="primary" @click="postLogin()" :loading="loading" :disabled="invalid">Verify</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <!-- Register -->
                <template v-if="stage === 'sign-up'">
                  <ValidationObserver ref="sign-up" v-slot="{ invalid }">
                    <v-card-text>                    
                      <v-form >
                        <ValidationProvider name="name" rules="required"  v-slot="{ errors, valid }">      
                          <v-text-field append-icon="person" label="First Name" v-model="signUpForm.firstName" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="lastname" rules="required"  v-slot="{ errors, valid }">      
                          <v-text-field append-icon="person" label="Last Name" v-model="signUpForm.lastName" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">      
                          <v-text-field append-icon="email" label="Email" v-model="signUpForm.email" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>
                      </v-form>
                      
                      <div>By clicking the 'Sign up' button, you are creating a Cleversight account and agree with our <a href="https://cleversight.one/wp-content/uploads/2022/11/Privacyverklaring-Cleversight-ENG-2022-1.pdf">Privacy Policy.</a></div>
                      <br>
                      <div>Having issues? Please contact <a href="mailto:support@cleversight.one">support</a> for help.</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn  @click="setCurrentStage('login')">Back</v-btn>
                      <v-btn color="primary" @click="postSignUp()" :loading="loading" :disabled="invalid">Sign Up</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <!-- Register -->
                <template v-if="stage === 'sign-up-send'">
                    <v-card-text>                    
                      Succesfully created account.
                      Please verify your email and return to Login.
                
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setCurrentStage('login')">Back to Login</v-btn>
                    </v-card-actions>
                </template>

                <!-- Complete Regsistration Register -->
                <template v-if="stage === 'sign-up-complete'">
                  <ValidationObserver ref="sign-up-complete" v-slot="{ invalid }">
                    <v-card-text>
                      <v-form >
                        <v-text-field append-icon="mdi-email" label="Email" :value="signUpCompleteForm.email" outlined disabled></v-text-field>
                        <ValidationProvider name="password" rules="required|min:8|passwordreg"  v-slot="{ errors, valid }">
                          <v-text-field label="Password" v-model="signUpCompleteForm.password" outlined @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="companyName" rules="required"  v-slot="{ errors, valid }">      
                          <v-text-field append-icon="mdi-domain" label="Company Name" v-model="signUpCompleteForm.companyName" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>
                        <ValidationProvider name="phone" rules="required|phone"  v-slot="{ errors, valid }">      
                          <v-text-field append-icon="mdi-phone" label="Phone" v-model="signUpCompleteForm.phone" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>   
                        <ValidationProvider name="billingMail" rules="required|email"  v-slot="{ errors, valid }">      
                          <v-text-field append-icon="mdi-email" label="Billing Email*" v-model="signUpCompleteForm.billingMail" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider> 
                      </v-form>
                      <div>*Just for registration, <strong>No</strong> fees will be charged!</div>
                      <br>
                      <div>Having issues? Please contact <a href="mailto:support@cleversight.one">support</a> for help.</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="postSignUpComplete()" :loading="loading" :disabled="invalid">Complete</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <!-- Register -->
                <template v-if="stage === 'sign-up-complete-send'">
                    <v-card-text>                    
                      Succesfully created account! Please return to login.
                
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setCurrentStage('login')">Back to Login</v-btn>
                    </v-card-actions>
                </template>

                <!-- Forget Password -->
                <template v-if="stage === 'password-reset'">
                  <ValidationObserver ref="password-reset" v-slot="{ invalid }">
                    <v-card-text>
                      <v-form>
                        <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">  
                          <v-text-field append-icon="person" label="Email" v-model="passwordResetForm.email" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>      
                      </v-form>
                      <div>Having issues? Please contact <a href="mailto:support@cleversight.one">support</a> for help.</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn  @click="setCurrentStage('login')">Back</v-btn>
                      <v-btn color="primary" @click="postPasswordReset()" :loading="loading" :disabled="invalid">Request Reset</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <template v-if="stage === 'password-reset-send'">
                    <v-card-text>                    
                      Succesfully send! Please check your email and return to Login.                
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setCurrentStage('login')">Back to Login</v-btn>
                    </v-card-actions>
                </template>

                <!-- Password reset -->
                <template v-if="stage === 'password-reset-complete'">
                  <ValidationObserver ref="password-reset-complete" v-slot="{ invalid }">
                    <v-card-text>
                      <v-form>
                        <v-text-field append-icon="mdi-email" label="Email" v-model="passwordResetCompleteForm.email" outlined disabled></v-text-field>
                        <ValidationProvider name="password" rules="required|min:8|passwordreg"  v-slot="{ errors, valid }">  
                          <v-text-field label="New Password" v-model="passwordResetCompleteForm.password" outlined @click:append="showPassword = !showPassword" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>
                      </v-form>
                      <div>Having issues? Please contact <a href="mailto:support@cleversight.one">support</a> for help.</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="postPasswordResetComplete()" :loading="loading" :disabled="invalid">Send</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <template v-if="stage === 'password-reset-complete-send'">
                    <v-card-text>                    
                      Succesfully updated password! Please return to Login.                
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setCurrentStage('login')">Back to Login</v-btn>
                    </v-card-actions>
                </template>


                <!-- Forget Two Factor -->
                <template v-if="stage === 'two-factor-reset'">
                  <ValidationObserver ref="two-factor-reset" v-slot="{ invalid }">
                    <v-card-text>
                      <v-form>
                        <ValidationProvider name="email" rules="required|email"  v-slot="{ errors, valid }">  
                          <v-text-field append-icon="person" label="Email" v-model="twoFactorResetForm.email" outlined :error-messages="errors" :success="valid"></v-text-field>
                        </ValidationProvider>      
                      </v-form>
                      <div>Having issues? Please contact <a href="mailto:support@cleversight.one">support</a> for help.</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn  @click="setCurrentStage('login')">Back</v-btn>
                      <v-btn color="primary" @click="postTwoFactorReset()" :loading="loading" :disabled="invalid">Request Reset</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <template v-if="stage === 'two-factor-reset-send'">
                    <v-card-text>                    
                      Succesfully send! Please check your email and return to Login.                
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setCurrentStage('login')">Back to Login</v-btn>
                    </v-card-actions>
                </template>



                <!-- 2FA reset -->
                <template v-if="stage === 'two-factor-reset-complete'">
      
                  <ValidationObserver ref="two-factor-reset-complete" v-slot="{ invalid }">
                    <v-card-text>
                      <div>Scan the QR or register the secret within with your favourite authenticator app and valiate with a token. Never store the token in a unsafe location.</div>
                      <br>
                      <v-row>
                        <v-layout align-center justify-center column fill-height>
                          <v-flex lg12 sm10 xs10 align-center>
                            <v-img v-if="!loadingTwoFactorSecret" max-width="300px" :src="twoFactorSecret.qr"/>
                            <v-progress-circular
                              v-else
                              :size="150"
                              :width="5"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </v-flex>
                        </v-layout>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-form>
                            <v-text-field v-if="!loadingTwoFactorSecret" append-icon="mdi-key" label="Secret" :value="twoFactorSecret.secret" outlined readonly></v-text-field>
                            <ValidationProvider name="password" rules="required"  v-slot="{ errors, valid }">  
                              <v-text-field label="Validation Token" v-model="twoFactorResetCompleteForm.twoFactorToken" outlined :error-messages="errors" :success="valid"></v-text-field>
                            </ValidationProvider>
                          </v-form>
                        </v-col>
                      </v-row>
                  
               
                      <div>Having issues? Please contact <a href="mailto:support@cleversight.one">support</a> for help.</div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="postTwoFactorResetComplete()" :loading="loading" :disabled="invalid">Activate</v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </template>

                <template v-if="stage === 'two-factor-reset-complete-send'">
                    <v-card-text>                    
                      Succesfully activated multi factor authentication! Please return to Login.           
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="setCurrentStage('login')">Back to Login</v-btn>
                    </v-card-actions>
                </template>

                <!-- Verify IP -->
                <template v-if="stage === 'verify-ip-address'">
                    <v-card-text>                    
             
                      <v-text-field append-icon="mdi-wan" label="IP address" v-model="verifyIpAddressForm.ipAddress" outlined disabled></v-text-field>

                      Please verify your email and return to Login.
                
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="setCurrentStage('login')">Return</v-btn>
                      <v-btn color="primary" @click="postVerifyIppAddress()">Verify</v-btn>                     
                    </v-card-actions>
                </template>

            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </div>

</template>

<script>

import { mapMutations } from 'vuex'

export default {

  async created() {
    await this.$recaptchaLoaded()
  },

  async mounted() {
    // display right page for payload urls
    if(this.$route.query.payload) {
      const payload = JSON.parse(atob(this.$route.query.payload))
      if(payload.type === 'sign-up-complete') {
        this.signUpCompleteForm.token = payload.token
        this.signUpCompleteForm.email = payload.email
        this.signUpCompleteForm.billingMail = payload.email
        await this.setCurrentStage('sign-up-complete')
      }
      if(payload.type === 'password-reset-complete') {
        this.passwordResetCompleteForm.token = payload.token
        this.passwordResetCompleteForm.email = payload.email
        await this.setCurrentStage('password-reset-complete')
      }
      if(payload.type === 'two-factor-reset-complete') {
        this.loadingTwoFactorSecret = true
        this.twoFactorResetCompleteForm.token = payload.token
        this.twoFactorResetCompleteForm.email = payload.email        
        await this.setCurrentStage('two-factor-reset-complete')
        const {data} = await this.$http.get(`/user/two-factor/${payload.token}`)
        this.twoFactorSecret = data
        this.loadingTwoFactorSecret = false
      }    
      if(payload.type === 'verify-ip-address') {
        this.verifyIpAddressForm.token = payload.token
        this.verifyIpAddressForm.ipAddress = payload.ipAddress
        await this.setCurrentStage('verify-ip-address')
      }
    }
  },

  methods: {
      ...mapMutations(['setAccessToken']),

      // sets the to show form
      setCurrentStage(stage) {
        this.stage = stage
      },

      // Post Methods
      async postLogin() {
        try {
          this.loading = true
          const {data} = await this.$http.post('/auth/login', this.loginForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('login')}`
            }
          });
          if(data.next === 'whoAmI') {
            const {accessToken} = data
            this.$http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            await this.setAccessToken(accessToken)
          }

          if(data.next === 'twoFactorTokenValidation') {
            this.setCurrentStage('twoFactorTokenValidation')
          }

          this.loading = false

        } catch (e) {
          this.loading = false
        }
      },

      async postSignUp() {
        try {
          this.loading = true
          await this.$http.post('/user/signUp', this.signUpForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('signUp')}`
            }
          });
          await this.setCurrentStage('sign-up-send')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      async postSignUpComplete() {
        try {
          this.loading = true
          await this.$http.post('/user/signUpComplete', this.signUpCompleteForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('signUpComplete')}`
            }
          });
          await this.setCurrentStage('sign-up-complete-send')
          this.$router.push('/')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      async postPasswordReset() {
        try {
          this.loading = true
          await this.$http.post('/user/passwordReset', this.passwordResetForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('passwordReset')}`
            }
          });
          await this.setCurrentStage('password-reset-send')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      async postPasswordResetComplete() {
        try {
          this.loading = true
          await this.$http.post('/user/passwordResetComplete', this.passwordResetCompleteForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('passwordResetComplete')}`
            }
          });
          await this.setCurrentStage('password-reset-complete-send')
          this.$router.push('/')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      
      async postTwoFactorReset() {
        try {
          
          this.loading = true
          await this.$http.post('/user/twoFactorReset', this.twoFactorResetForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('twoFactorReset')}`
                  }
          });
          await this.setCurrentStage('two-factor-reset-send')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },

      async postTwoFactorResetComplete() {
        try {
          this.loading = true
          await this.$http.post('/user/twoFactorResetComplete', this.twoFactorResetCompleteForm, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('twoFactorResetComplete')}`
            }
          });
          await this.setCurrentStage('two-factor-reset-complete-send')
          this.$router.push('/')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },      

      async postVerifyIppAddress() {
        try {
          this.loading = true
          await this.$http.patch(`/known-ip-addresses/${this.verifyIpAddressForm.token}`,{verified : true}, {
            headers : {
              recaptcha : `Token ${await this.$recaptcha('twoFactorResetComplete')}`
            }
          });
          await this.setCurrentStage('login')
          this.$router.push('/')
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      }, 

      async getRecaptchaToken(action) {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha(action)
        return token
      }

  },

  data: () => {
    return {
      stage: 'login',
      loading: false,
      loadingTwoFactorSecret: false,
      showPassword: false,
      loginForm: {
        email: "",
        password: "",
        twoFactorToken: ""
      },
      signUpForm: {
        email: "",
        firstName: "",
        lastName: ""
      },
      signUpCompleteForm: {
        token: "",
        email: "",
        password: "",
        phone: "",
        companyName: "",
        billingMail: "",
      },
      passwordResetForm: {
        email: ""
      },
      passwordResetCompleteForm: {
        token: "",
        email: "",
        password: "",
      },
      twoFactorResetForm: {
        email: ""
      },
      twoFactorResetCompleteForm: {
        token: "",
        email: "",
        twoFactorToken: "",
      },
      twoFactorSecret: {
        secret: "",
        qr: ""
      },
      verifyIpAddressForm: {
        token : "",
        ipAddress : ""
      }
    }
  }

};
</script>
<style scoped lang="css">
  #pagelogin {
    background-image: url('../assets/background-gradient.png');
    background-size: cover;
    height: 100vh;
  }
</style>
